import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { collectionToStories } from "@quintype/components";
import CardImageType4 from "../../molecules/card-image-type-4";
import CardImageType5 from "../../molecules/card-image-type-5";
import CardImageType8 from "../../molecules/card-image-type-8";
import { SectionCategoryTitle } from "../../molecules/section-category-title";
//import CollectionEnd  from "../../molecules/collection-end";

import "./one-big-3-small-img-news-cols.m.css";

const OneBigThreeSmallImgNewsCols = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const pointerAdConfig = get(collection, ["associated-metadata", "pointer_ad"], true);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);

  const storiesFromCollection = collectionToStories(collection);

  if (storiesFromCollection.length < 1) {
    return null;
  }
  const [firstStory, ...restOftheStories] = storiesFromCollection || [];

const heroImageS3 = firstStory["hero-image-s3-key"]

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
            {showSection &&<div><SectionCategoryTitle name={collection.name} /></div>}
            {<div styleName="first-story first-story1">
              <CardImageType5 story={firstStory} showSubHeadline ={false}/>
            </div>}
            <div styleName="item-wrapper1">
              {restOftheStories.length >= 1 &&
                restOftheStories.slice(0, 3).map((item, index) =>
                  <React.Fragment key={index}>
                    <div styleName="item" className="item" key={item.id}>
                      <div styleName="desktop-tablet-only">
                        <CardImageType4 story={item} />
                        </div>
                        <div styleName="mobile-only">
                        <CardImageType8 story={item} />
                        </div>
                    </div>
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

OneBigThreeSmallImgNewsCols.propTypes = {
  collection: object
};
export { OneBigThreeSmallImgNewsCols };

OneBigThreeSmallImgNewsCols.storyLimit = 22;
