import { BlockQuote } from "@quintype/arrow";
import React, { useEffect, useState } from "react";
import useStorage from "../../../../hook/useStorage";
import './profile-info.m.css';
import { createUrl } from "../../../utils/createurl";
import { getUserData } from "../../../utils/getUserData";
const ProfileInfo = () => {
  const [checkUser, activeProducts, whiteListed, _, visitCount, articlesLimit, isVisitedSlug] = useStorage();
  if (articlesLimit) {
    //console.log("values", checkUser, activeProducts, whiteListed, visitCount, articlesLimit, isVisitedSlug)
  }
  const myaccount=createUrl("myaccount");
  const subscriptions=createUrl("subscriptions");
  const changeaddress=createUrl("changeaddress");
  const changetemporaryaddress=createUrl("changetemporaryaddress");
  const intermission=createUrl("intermission");
  const invoice=createUrl("invoice");

  let username = "";
  let activeproductNames = [];
  let itemsList;
  let email = "";
  const data= getUserData();
  var usernam = data.username;
  var emailId = data.email;
  if (typeof window === 'undefined') {
    return false;
  } else {
    if (usernam == null || usernam == "undefined" || username == null) {
      username = "";
    } else {
      username = usernam;
    }

    if (emailId == null || emailId == "undefined" || email == null) {
      email = "";
    } else {
      email = emailId;
    }

    activeproductNames = window.localStorage.getItem("activeProductNames");
    itemsList = activeproductNames.split(",");
  }

  return <div styleName="profile-info-container">

    <h1>Omat tietoni</h1>
    {checkUser &&
      <div styleName="profile-body">
        <div styleName="userinfo">
          <ul>
            <li style={{ fontWeight: 600 }}>
              {username}
            </li>
            <li>
              {email}
            </li>
          </ul>
        </div>
        <div styleName="profile-container">
          {/*<li>Status: Logged in</li>*/}

          {/*<h1 styleName="status">Active Products:</h1>
        <ul>
          {activeProducts && itemsList.map((items, index) => <li key={index}>{`[${items}]`}</li>)}
        </ul>*/}
          <div styleName="profile-left">
            <ul>
              <a styleName="tulolinks" href={myaccount} target="_blank">
                <li>
                  Omat tiedot
                  <i styleName="profileinfo-link-icon" className="ri-arrow-right-s-line"></i>
                </li>
              </a>
              {!activeProducts &&
                <a styleName="tulolinks" href="https://asiakaspalvelu.i-mediat.fi/ilkkapohjalainen/tilaa" target="_blank">
                  <li>
                    Siirry tilaamaan
                    <i styleName="profileinfo-link-icon" className="ri-arrow-right-s-line"></i>
                  </li>
                </a>}
              {activeProducts &&
                <>
                  <a styleName="tulolinks" href={subscriptions} target="_blank">
                    <li>
                      Tilaukseni
                      <i styleName="profileinfo-link-icon" className="ri-arrow-right-s-line"></i>
                    </li>
                  </a>
                  <a styleName="tulolinks" href={changeaddress} target="_blank">
                    <li>
                      Pysyvä osoitteenmuutos
                      <i styleName="profileinfo-link-icon" className="ri-arrow-right-s-line"></i>
                    </li>
                  </a>
                  <a styleName="tulolinks" href={changetemporaryaddress} target="_blank">
                    <li>
                      Väliaikainen osoitteenmuutos
                      <i styleName="profileinfo-link-icon" className="ri-arrow-right-s-line"></i>
                    </li>
                  </a>
                  <a styleName="tulolinks" href={intermission} target="_blank">
                    <li>
                      Jakelunkeskeytys
                      <i styleName="profileinfo-link-icon" className="ri-arrow-right-s-line"></i>
                    </li>
                  </a>
                  <a styleName="tulolinks" href={invoice} target="_blank">
                    <li>
                      Laskut
                      <i styleName="profileinfo-link-icon" className="ri-arrow-right-s-line"></i>
                    </li>
                  </a>
                </>}
            </ul>
          </div>
          <div styleName="profile-right">
            <ul>
              <li>
                Tältä sivulta pääset hallitsemaan tilaustasi.
                <br />
                <br />
              </li>
              <li>
                <a href="https://asiakaspalvelu.i-mediat.fi/ilkkapohjalainen/asiakastuki/" target="_blank">Asiakastukisivustolta</a> löydät vastauksia usein kysyttyihin kysymyksiin.
                <br />
                <br />
              </li>
              <li>
                Tarvittaessa voit ottaa yhteyttä <a href="https://asiakaspalvelu.i-mediat.fi/ilkkapohjalainen/ota-yhteytta" target="_blank">asiakaspalveluumme</a>.
              </li>
            </ul>
          </div>
        </div>
      </div>
    }
  </div >

}

export default ProfileInfo;
