import React from "react";
import { object } from "prop-types";
import "./pagination-story.m.css";
import CardImageType6 from "../card-image-type-6";

const PaginationStory = ({ story }) => {
  return (
    <div styleName="story-card">
      <div className="item" styleName="item">
        <CardImageType6 story={story} showSubHeadline={false} />
      </div>
    </div>
  );
};

export default PaginationStory;

PaginationStory.propTypes = {
  story: object
};
