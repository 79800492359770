import React, { useState, useEffect } from "react";
import axios from "axios";
import "./tulo-active-session-page.m.css";
import { Link } from "@quintype/components";
import { createBrowserHistory } from "history";
import Uusimmat from "../../story-templates/uusimmat";

const TuloActiveSessions = () => {
  const [sessionData, setSessionData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [allowedLimits, setAllowedLimits] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorMessageInDome, setErrorMessageInDome] = useState(false);
  let history;
  const handleGoBack = () => {
    if (typeof window === "undefined") {
      return;
    } else {
      const errorElement = document.getElementById("unique-message");
      const errorMessageInDome = errorElement && errorElement.textContent.trim().length > 0;
      setErrorMessageInDome(errorMessageInDome);
      if (!errorMessageInDome) {
        history = createBrowserHistory();
        history.back();
      }
    }
  };
  useEffect(() => {
    axios
      .get("/tulo/active-sessions")
      .then((response) => {
        setSessionData(response.data.activeSessions);
        setAllowedLimits(response.data.limits);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        console.error("Error fetching active sessions:", error);
      });
    if (typeof window === "undefined") {
      return;
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has("selectSession")) {
        const message = queryParams.get("selectSession");
        setErrorMessage(message);
        history = createBrowserHistory();
        history.push("/tulo/activeSessions");
      }
    }
  }, []);
  return (
    <div styleName="top-container">
    <div styleName="main-container">
      <div styleName="main"></div>
      <div styleName="active-sessions-container">
        <div styleName="active-sessions-left-container">
          <div styleName="modal-container" className="container">
            {!loading ? (
              <div>
                <div>
                  {errorMessage && (
                    <div>
                      <h2 id="unique-message" styleName="error-message">
                        {errorMessage}
                      </h2>
                    </div>
                  )}
                  <h1>Hups, sinulla on liian monta avointa istuntoa</h1>
                  <p styleName="session-message">
                    {`Voit olla yhtä aikaa sisäänkirjautuneena ${allowedLimits} selaimessa.
                    Valitse alta, minkä istunnon haluat sulkea, että voit jatkaa sisäänkirjautumista.`}
                  </p>
                </div>
                <div className="speech-bottom" styleName="speech-bottom">
                  <h1>Oma tunnus kaikille perheenjäsenille</h1>
                  Omien tietojen <a href="https://imediat.portal.worldoftulo.com/account/subscriptions">Tilaukset</a>-sivulla voit jakaa lukuoikeuden kolmelle samassa taloudessa asuvalle perheenjäsenelle. Tarkemmat ohjeet löydät <a href="https://asiakaspalvelu.i-mediat.fi/ilkkapohjalainen/asiakastuki/">Asiakastuki</a>-sivuilta.
                </div>
                <form method="POST" action="/tulo/destroyActSessAndLoginWithOtp">
                  <ul>
                    <li>
                    {sessionData.map((session) => {
                        const date = session.last_seen.substring(0, 10);
                        const time = session.last_seen.substring(11, 16);
                        return (
                          <div key={session.session_id}>
                            <div styleName="session-container">
                              <div styleName="device-container">
                                <label class="checkbox-container">
                                  <input type="checkbox" name="sessionsToLogout" value={session.session_id} />
                                  <span class="checkmark"></span>
                                </label>
                                <p>{`${session.browser} – ${session.operating_system}`}</p>
                              </div>
                              <ul styleName="session-details">
                                <li><span>Viimeksi nähty: </span>{`${date} klo ${time}`}</li>
                                <li><span>IP osoite: </span>{`${session.ip_address}`}</li>
                                {/*<li><span>Created</span>{session.created}</li>*/}
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                    </li>
                  </ul>
                  <br />
                  <div styleName="btns">
                    <button styleName="session-logout" type="submit">
                      Kirjaudu ulos valituista selaimista
                    </button>
                    <Link href={errorMessageInDome ? "/" : undefined}>
                      <span styleName="close-button" onClick={!errorMessageInDome ? handleGoBack : undefined}>
                        Peruuta
                      </span>
                    </Link>
                  </div>
                </form>
              </div>
            ) : (
              <div styleName="loading-indicator">
                <i className="ri-loader-line"></i>
              </div>
            )}
          </div>
        </div>
        <div styleName="sidebar">
          <div styleName="sidebar-container">
            <Uusimmat />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export { TuloActiveSessions };
