/* eslint-disable import/extensions */
import { get } from "lodash";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import SideBar from "../molecules/sidebar";
import "./home.m.css";
import RightNow from "../story-templates/right-now";
import { bool, number, object, shape } from "prop-types";
import { EtusivuSuurtaulu } from "../atoms/suurtaulu-frontpage";
import { getCollectionTemplate } from "../get-collection-template";
import {LazyCollection, LazyLoadImages, replaceAllStoriesInCollection, WithPreview } from "@quintype/components";

import "./home.m.css";

const Home = ({ data, reloadTimeInMinutes = 15, shouldReload = false }) => {
  const { rightNow } = data;

  useEffect(() => {
    if (!shouldReload) return;
    let timeElapsed = 0;
    let timerId;
    const Main = () => {
      if (document.hidden && timeElapsed === 0) {
        startTimer();
      }
      if (!document.hidden && timeElapsed > 0 && timeElapsed < reloadTimeInMinutes) {
        clearInterval(timerId);
        timeElapsed = 0;
      }
      if (!document.hidden && timeElapsed >= reloadTimeInMinutes) {
        window.location.reload();
      }
    };

    function updateTimeElapsed() {
      timeElapsed = timeElapsed + 1;
    }
    function startTimer() {
      timerId = window.setInterval(updateTimeElapsed, 60000);
    }
    document.addEventListener("visibilitychange", Main);
    return () => {
      document.removeEventListener("visibilitychange", Main);
    };
  }, []);
  return (
    <React.Fragment>
      <div styleName="container">
        <div styleName="main">
          <div styleName="ad-container">
            {/* PARAATI mobiili*/}
            <div styleName="mob-ad">
              <div id="sas_63333" data-placement-id="paraatipaikka-mobile"></div>
            </div>
            {/* PARAATI deskari ja tabletti*/}
            <div styleName="dsktop-ad">
              <div id="sas_63219" data-placement-id="paraatipaikka-desktop"></div>
            </div>
          </div>
          <div styleName="ad-container">
            {/* PARAATI 2 mobiili*/}
            <div styleName="mob-ad">
              <div id="sas_112313" data-placement-id="paraatipaikka-2-mobile"></div>
            </div>
            {/* PARAATI 2 deskari ja tabletti*/}
            <div styleName="dsktop-ad">
              <div id="sas_112312" data-placement-id="paraatipaikka-2-desktop"></div>
            </div>
          </div>
            <EtusivuSuurtaulu />
        </div>
        <div styleName="lazy-pop">
          <div styleName="lazy-load">
            <div styleName="right-now">
              <RightNow rightNow={rightNow} />
            </div>
            <LazyLoadImages>
              <LazyCollection collection={data.collection} collectionTemplates={getCollectionTemplate} lazyAfter={22} />
            </LazyLoadImages>
          </div>
          <SideBar/>
        </div>
      </div>
    </React.Fragment>
  );
};

Home.propTypes = {
  data: shape({
    collection: object
  }),
  reloadTimeInMinutes: number,
  shouldReload: bool
};

function mapStateToProps(state) {
  return {
    shouldReload: get(state, ["qt", "config", "publisher-attributes", "shouldReload"], false),
    reloadTimeInMinutes: Number(get(state, ["qt", "config", "publisher-attributes", "reloadTimeInMinutes"], 15))
  };
}

export const HomePage = connect(mapStateToProps, () => ({}))(Home);

export const HomePagePreview = WithPreview(HomePage, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story)
  })
);
