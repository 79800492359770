import React from "react";
import { object, number, array } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { ResponsiveImage, Link } from "@quintype/components";
import { SectionTag } from "@quintype/arrow";

import "./card-image-type-1.m.css";

const CardImageType1 = ({ story, defaultImage, headlineLimit, widths = [250, 375] }) => {
  const storyObj = story.story ? story.story : story;
  const imgURL = storyObj["hero-image-url"];
  const imgS3keys= storyObj["hero-image-s3-key"];
  const getIcon = type => {
    if (type === "photo") {
      return (
        <div styleName="icon">
          <img src="/assetify/photo.svg" alt="photo" />
        </div>
      );
    } else if (type === "video") {
      return (
        <div styleName="icon">
          <img src="/assetify/video.svg" alt="video" />
        </div>
      );
    }
  };

  return (
    <Link href={`/${storyObj.slug}`}>
      <div styleName="wrapper" className="card-image-type-1">
       {imgS3keys !==null ? <figure styleName="image302x403">
          {/* Desktop WebP */}
          {imgURL ? <img styleName="image" className="image" src={storyObj["hero-image-url"]} />:
          <ResponsiveImage
            slug={`${storyObj["hero-image-s3-key"] ? storyObj["hero-image-s3-key"] : defaultImage}`}
            metadata={storyObj["hero-image-metadata"]}
            aspectRatio={[302, 403]}
            defaultWidth={259}
            className="image"
            styleName="image"
            alt={storyObj["hero-image-caption"] && storyObj["hero-image-caption"]}
          />}
          <div styleName="info">
            {getIcon(storyObj["story-template"])}
            <h2 styleName="headline">{storyObj.headline}</h2>
          </div>
        </figure>:
      <div styleName="custom-section-tag" className="custom-section-tag">
      <SectionTag story={storyObj} />
      <h2 styleName="headline" className="main-headline">{storyObj.headline}</h2>
    </div>
        }
      </div>

    </Link>
  );
};
function mapStateToProps(state) {
  return {
    defaultImage: get(state, ["qt", "config", "publisher-attributes", "defaultImageKey"], "")
  };
}
export default connect(mapStateToProps, () => ({}))(CardImageType1);
CardImageType1.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array
};
