import React, { useEffect, useState } from "react";
import { array } from "prop-types";
import sortBy from 'lodash/sortBy';
import { Link } from "@quintype/components";
import useUrl from "../../../../hook/useUrl";
import TimeStamp from "../../../atoms/time-stamp";
import StarShowHide from "../../../atoms/star-show-hide";
import { BookmarkIcon } from "../../../molecules/bookmark-icon";
import { generateProfileHref } from "../../../utils/generate-profile-href";
import { postBookmark } from "../../../utils/post-bookmark";
import CardImageType8 from "../../../molecules/card-image-type-8";
import { LoadmoreButton } from "../../../atoms/Loadmore-btn";
import BookmarkPopup from "../../../molecules/bookmark-popup";
import SectionShowMoreStories from "../../../molecules/section-show-more-stories";
import { ShowMoreBtn } from "../../../atoms/show-more-btn";
import "./saved-stories-layout.m.css";
import { generatePaperValues } from "../../../utils/generate-paper-values";

const SavedStoriesLayout = ({ followedtags, hideTags, story, tagKpl = false, removeStory = true }) => {
  const [toDelIds, setToDelIds] = useState([]);
  const remainingStories = (story.story ? story.story : story) || [];
  const storyObj = (remainingStories && remainingStories.filter(strId => strId && !toDelIds.includes(strId.id))) || [];
  const theme = false;
  //const storyObjReversed = storyObj.reverse();
  const [_, currentUrlSlug] = useUrl();
  const [isVisible, setIsVisible] = useState(true);
  const [storiesDeleted, setStoriesDeleted] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [storyLimit, setStoryLimit] = useState(30);
  const [showBookmark, setShowBookmark] = useState(true);
  const onItemsToLoadClick = () => setStoryLimit(storyLimit + 10);

  const puhlishedAt = sortBy(storyObj, [function (o) {
    return o["published-at"];
  }])
  const storyObjReversed = puhlishedAt.reverse();
  const storyObjFormatted = !tagKpl ? storyObjReversed : storyObjReversed.slice(0, 30);
  const page = tagKpl ? "tag" : "";

  const onClickDelete = (e, id) => {
    e.preventDefault();
    id && setToDelIds([...toDelIds.concat(id)]);
    postBookmark(e, id, false);
  };

  const deleteAllStories = (e, storyObj) => {
    if (typeof window !== 'undefined') {
      storyObj.map(story => {
        onClickDelete(e, story.id);
      });
      setStoriesDeleted(true);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (page !== "tag") {
      setShowBookmark(false);
    }
  }, [page]);

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <h1>
            {!tagKpl && "Tallentamani artikkelit"}
            {(!tagKpl && !storiesDeleted) && <span styleName="saved-stories-amount">{storyObj.length >= 1 && `${storyObj.length} kpl`}</span>}
          </h1>
          {!tagKpl && <p styleName="saved-stories-text">
            Tällä sivulla näet tallentamasi artikkelit. Voit poistaa artikkelin tallennuksen painamalla
            kirjanmerkki-ikonia (<i className="ri-bookmark-fill"></i>). Voit poistaa kaikki tallentamasi artikkelit painikkeesta "Tyhjennä lista".
            <br /><br />
            Valitsemasi artikkelit tallennetaan ja niistä saattaa muodostua sinua profiloivia tietoja. Muistathan siis huolehtia tilisi ja salasanasi turvaamisesta!
            <br /><br />
            {(!tagKpl && !storiesDeleted && storyObj.length > 0) && <LoadmoreButton onClick={() => setShowDeletePopup(true)}
              btnText="Tyhjennä lista" />}
          </p>}
          {showDeletePopup ? <BookmarkPopup closePopup={() => setShowDeletePopup(false)} feature={"delete-all-stories"} deleteAll={deleteAllStories} stories={storyObj} /> : ""}


          <div styleName="inner-wrapper1">
            <div styleName="item-wrapper1" className="profile-item-wrapper">
              {(!tagKpl && (storyObj.length == 0 || storiesDeleted)) &&
                <p>Et ole tallentanut yhtään artikkelia</p>}
              {(!storiesDeleted && !hideTags) && (storyObj.length >= 1 &&
                storyObjFormatted.map((item, index) => {
                  const paperValues = generatePaperValues(item);
                  const storyTagNames = item.tags && (item.tags).map(tag => tag.name);
                  const followedtagsLower = followedtags && followedtags.map(tag => tag.toLowerCase());
                  const storytags = (storyTagNames && followedtags) && storyTagNames.filter(tagname => followedtagsLower.includes(tagname.toLowerCase()));
                  return (
                    <React.Fragment key={index}>
                      {item["hero-image-s3-key"] !== null ? (
                        <div styleName="item" className="item" key={item.id}>
                          <CardImageType8
                            onClickDelete={e => onClickDelete(e, item.id)}
                            story={item}
                            showSubHeadline={false}
                            showBookmark={false}
                            removeStory={removeStory}
                            storytags={storytags}
                            page = "profile"
                          />
                        </div>
                      ) : (
                        <div className="main-headline item" styleName="story-content">
                          <div styleName="wrapper-inside" className="wrapper-inside">
                            <h2 styleName="" className="headline-layout4">
                              <Link className="main-headline-layout1 layout4" href={generateProfileHref(item,currentUrlSlug)}>
                                <span styleName="span-newest">{`${item.sections[0].name} | `}</span>
                                {item.headline}
                              </Link>
                            </h2>
                            <div styleName="section-time-wrapper">
                              <div className="time-stamp-layout1">
                                <TimeStamp timeStamp={item["published-at"]} prefix={paperValues.paperName}/>
                              </div>
                              {(paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ") ? null : <StarShowHide accessType={paperValues.accessType} />}
                              <BookmarkIcon user_flag="bm_user_flag" storyObj={storyObj} showBookmark={showBookmark} />
                              {removeStory && <button
                                styleName="bookmark-btn"
                                onClick={e => onClickDelete(e, item.id)}
                                aria-label="Poista tallennus"
                                title="Poista tallennus">
                                <i className={`ri-bookmark-fill`} styleName="bookmark-fill"></i>
                              </button>}
                            </div>
                            <div styleName="storytags" className="storytags">
                              {storytags && storytags.join(', ')}
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                }))
              }
              {(tagKpl && storyObjReversed.length > 30) &&
                <SectionShowMoreStories stories={storyObjReversed} startIndex={30} itemsToLoad={storyLimit} followedtags={followedtags} page={page} />}
              {(tagKpl && storyObjReversed.length > 30) && (storyObjReversed.length > 30 && (storyObjReversed.length >= storyLimit + 1)) ?
                <ShowMoreBtn loadMore={onItemsToLoadClick} btnName="NÄYTÄ LISÄÄ" /> : null}

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SavedStoriesLayout.propTypes = {
  story: array
};

export default SavedStoriesLayout;
