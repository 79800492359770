import React from "react";
import useUrl from "../../../hook/useUrl";
import { Link } from "@quintype/components";
//import TimeStamp from "../../atoms/time-stamp";
import CardImage from "../../atoms/card-image";
import { BookmarkIcon } from "../bookmark-icon";
import { object, number, array, bool } from "prop-types";
import StarShowHide from "../../atoms/star-show-hide";
import { SectionCategoryTitle } from "../section-category-title";
import { generateHref } from "../../utils/generate-href";
import { CommentIcon } from "../comment-icon";

import "./card-image-type-6.m.css";
import { generatePaperValues } from "../../utils/generate-paper-values";
import { getTimeStampLayout } from "../../utils/getTimeStampLayout";

const CardImageType6 = ({ story, showSection = false, showSubHeadline = false, widths = [250, 375] }) => {
  const storyObj = story.story ? story.story : story;
  const paaaihe = storyObj.metadata ? storyObj.metadata["pääaihe"] : undefined;
  const imgS3keys = storyObj["hero-image-s3-key"];
  var paperValues = generatePaperValues(storyObj);
  const [_,currentUrlSlug] = useUrl();
  let accessType = storyObj.access;
  let domainSlug = "";
  switch (currentUrlSlug) {
    case "vaasalehti":
      domainSlug = "vaasa";
      accessType = "public";
      break;
    case "epari":
      domainSlug = "epari";
      accessType = "public";
      break;
    default:
      domainSlug = "ilkka-pohjalainen";
  }
  const getIcon = type => {
    if (type === "photo") {
      return (
        <div styleName="icon">
          <img src="/assetify/photo.svg" alt="photo" />
        </div>
      );
    } else if (type === "video") {
      return (
        <div styleName="icon">
          <img src="/assetify/video.svg" alt="video-test" />
        </div>
      );
    }
  };
  return (
    <div styleName="wrapper" className="card-image-type-6">
      <div
        className="main-headline story-content-no-img"
        styleName={imgS3keys ? "story-content" : "story-content-no-img"}
      >
        <div styleName="wrapper-inside" className="wrapper-inside">
          <Link href={generateHref(storyObj,paperValues.storysCurrentUrlSlug)}>
            <h2 styleName="headline" className="headline-layout4 main-headline-layout1 layout4">
              <span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>
              {storyObj.headline}
            </h2>
            {showSubHeadline && (
              <h5 styleName="headline" className="subheadline">
                <Link className="subheadline" href={`/${storyObj.slug}`}>
                  {storyObj.subheadline}
                </Link>
              </h5>
            )}
          </Link>
          <div styleName="section-time-wrapper">
            {getTimeStampLayout(domainSlug, paperValues, storyObj)}
            {(paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ") ? null : <StarShowHide accessType={paperValues.accessType} />}
            <CommentIcon storyObj={storyObj} />
            <BookmarkIcon
              storyObj={storyObj}
              showBookmark={true}
              removeStory={false}
              user_flag="bm_user_flag"
            />
          </div>
        </div>
      </div>
      <Link href={generateHref(storyObj,paperValues.storysCurrentUrlSlug)}>
        <div styleName="card-image-type-6" className="card-image-type-6-card">
          {showSection && <SectionCategoryTitle name={storyObj.sections[0].name} />}
          {imgS3keys !== null && (
            <div>
              <CardImage
                story={storyObj}
                defaultWidth={259}
                aspectRatio={[3, 2]}
                imageType="image3x2"
                widths={widths}
              />
              {(storyObj["story-template"] === "photo" || storyObj["story-template"] === "video") && (
                <div styleName="info" className="info">
                  {getIcon(storyObj["story-template"])}
                </div>
              )}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default CardImageType6;

CardImageType6.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array,
  showSection: bool,
  showSubHeadline: bool
};
