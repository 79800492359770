import templates from "./collection-templates/index.js";
import electiontemplates from "./collection-templates/election-templates.js";

export function getCollectionTemplate(designTemplate, index) {
  return templates[designTemplate] || templates.defaultTemplate;
}

export function getElectionCollectionTemplate(designTemplate, index) {
  return electiontemplates[designTemplate] || electiontemplates.defaultTemplate;
}

const storyLimits = Object.entries(templates).reduce(
  (acc, [key, value]) => Object.assign(acc, { [key]: value.storyLimit }),
  {}
);

export function getStoryLimits() {
  return storyLimits;
}
