import React, { useEffect, useState } from "react";
import SavedStoriesLayout from "../saved-stories-layout";
import get from 'lodash/get';
import map from 'lodash/map';
import "./saved-tagged-stories.m.css";

const SavedTaggedStories = ({ tagNames, config, hideTags }) => {
  const [tagStories, setTagStories] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const domains = ["ilkka-pohjalainen", "epari", "vaasa"];
  const sections = get(config, 'sections').filter(section => domains.includes(section["domain-slug"]));
  //section ids related to ilkka-pohjalainen domain slug:
  const sectionIds = map(sections, obj => get(obj, 'id')).join(',');
  useEffect(() => {
    let isMounted = true;
    async function fetchStoriesByTags() {
      try {
        const responses = await Promise.all(
          tagNames.map(
            async tnames =>
              await fetch(`/api/v1/advanced-search?tag-name-lower=${tnames.toLowerCase()}&limit=30&section-id=${sectionIds}&sort=latest-published`)
                .then(value => value.json())
                .then(json => json.items)
          )
        );
        const tagRes = responses.map(item => {
          if (item.length < 0) {
            return null;
          } else {
            return item;
          }
        });
        if (isMounted) {
          setTagStories(tagRes);
        }

      } catch (err) {
        console.log("some error", err)
      }
    }
    fetchStoriesByTags();
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 1000);
    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, [tagNames]);
  const mergedArrays = [].concat(...tagStories);
  const uniqueObjects = {};
  mergedArrays.forEach(obj => {
    uniqueObjects[obj.id] = obj;
  });
  const uniqueStories = Object.values(uniqueObjects);
  return (
    <div styleName="saved-stories-container">
      <div>
        {(tagNames.length >= 1 && uniqueStories.length >= 1) && <div><SavedStoriesLayout followedtags={tagNames} hideTags={hideTags} story={uniqueStories} tagKpl={true} removeStory={false} /></div>}
      </div>

      {!(tagNames.length >= 1 && uniqueStories.length >= 1) && (isVisible && (<div styleName="custom-loader"></div>))}
    </div>
  );
};

export default SavedTaggedStories;
