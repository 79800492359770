import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { collectionToStories } from "@quintype/components";
import CardImageType8 from "../../molecules/card-image-type-8";
import { SectionCategoryTitle } from "../../molecules/section-category-title";
import "./five-small-img-news-rows.m.css";

const FiveSmallImgNewsRows = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);

  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [firstStory, ...restOftheStories] = storiesFromCollection || [];


  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
        {showSection &&<div><SectionCategoryTitle name={collection.name} /></div>}
          <div styleName="item-wrapper1">
            {storiesFromCollection.length >= 1 &&
              storiesFromCollection.slice(0, 5).map((item, index) =>
                <React.Fragment key={index}>
                    <div styleName="item" className="item" key={item.id}>
                      <CardImageType8 story={item} showSubHeadline={false} />
                    </div>
                </React.Fragment>
              )
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

FiveSmallImgNewsRows.propTypes = {
  collection: object
};

export { FiveSmallImgNewsRows };

FiveSmallImgNewsRows.storyLimit = 22;
