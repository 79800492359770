import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";
import TimeStamp from "../../../atoms/time-stamp";
import StarShowHide from "../../../atoms/star-show-hide";
import { BookmarkIcon } from "../../../molecules/bookmark-icon";
import { generatePaperValues } from "../../../utils/generate-paper-values";

import "./suosituimmat-child.m.css";

const SuosituimmatChild = ({ item }) => {
  function findByKey(key, value) {
    return (item, i) => item[key] === value;
  }
  const findParams = findByKey("domain-slug", "ilkka-pohjalainen");
  const positionOfPaper = item.story.sections.findIndex(findParams);
  var parts = item.story.slug.lastIndexOf("/");
  var storySlug = item.story.slug.substring(parts + 1);
  if (item.story.sections[positionOfPaper]) {
    var splitUrl = item.story.sections[positionOfPaper]["section-url"].split("/");
    var joinUrl = splitUrl.slice(3);
    var finalUrl = joinUrl.join("/");
  }
  const storyObj = story.story ? story.story : story;
  var paperValues = generatePaperValues(storyObj);
  const accessType = item.story.access;
  const paaaihe = item.story.metadata["pääaihe"];
  return (
    <div styleName="wrapper" className="wrapper-newest-category">
      <h2 styleName="headline">
        <Link
          className="main-headline"
          href={
            positionOfPaper !== -1
              ? item.story.sections[positionOfPaper]
                ? `/${finalUrl}/${storySlug}`
                : `/${item.story.sections[positionOfPaper].slug}/${storySlug}`
              : `/${item.story.slug}`
          }
        >
          <span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>
          {item.story.headline}
        </Link>
      </h2>
      <div styleName="section-time-wrapper" className="section-time-wrapper">
        <div>
          <TimeStamp timeStamp={item.story["published-at"]} />
        </div>
        {(paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ") ? null : <StarShowHide accessType={accessType} />}
      </div>
    </div>
  );
};

SuosituimmatChild.propTypes = {
  collectionslug: PT.string
};

export default SuosituimmatChild;
