import React, { useEffect } from "react";

const BullsGames = () => {

  useEffect(() => {
    const tag = document.createElement("script");
    tag.src = "https://puzzleplayz.com/embed/container?condition=1&type=l&val=bpfiipp&domain=ilkkapohjalainen.fi";
    tag.async = true;
    document.body.appendChild(tag);
    window.disableAjaxNavigation = true;
  }, []);

  return (
    <>
      <div id="bulls_container" />
    </>
  );
};
export { BullsGames };
