import React, { useEffect, useState } from "react";
import { Link } from "@quintype/components";
import { object, number, array, bool } from "prop-types";
import { SectionCategoryTitle } from "../section-category-title";
import { generateHref } from "../../utils/generate-href";
import StarShowHide from "../../atoms/star-show-hide";
import { BookmarkIcon } from "../bookmark-icon";
import { CommentIcon } from "../comment-icon";
import TimeStamp from "../../atoms/time-stamp";
import CardImage from "../../atoms/card-image";
import useUrl from "../../../hook/useUrl";
import "./card-image-type-7.m.css";
import { generatePaperValues } from "../../utils/generate-paper-values";
import { getTimeStampLayout } from "../../utils/getTimeStampLayout";

const CardImageType7 = ({ story, showSection = false, showSubHeadline = false, widths = [250, 375] }) => {
  const [_,currentUrlSlug]=useUrl();
  const storyObj = story.story ? story.story : story;
  var paperValues = generatePaperValues(storyObj);
  const paaaihe = storyObj.metadata ? storyObj.metadata["pääaihe"] : undefined;
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [isCollectionPage, setIsCollectionPage] = useState(false);
  var strippedTextBlock;
  if (storyObj && storyObj.cards) {
    if (storyObj.cards[0]["story-elements"]) {
      var firstTextBlock = storyObj.cards[0]["story-elements"][0].text || undefined;
      strippedTextBlock = firstTextBlock && firstTextBlock.replace(/(<([^>]+)>)/gi, "");
      if (firstTextBlock === '' || firstTextBlock === undefined || firstTextBlock === null) strippedTextBlock === "";
      else strippedTextBlock = firstTextBlock.replace(/(<([^>]+)>)/gi, "");
    }
  }
  else (strippedTextBlock = '');

  const imgS3keys = storyObj["hero-image-s3-key"];
  let accessType = storyObj.access;
  let domainSlug=""
  if(currentUrlSlug==="vaasalehti"){
    domainSlug="vaasa";
    accessType="public";
  }else{
    domainSlug="ilkka-pohjalainen"
  }
  const getIcon = type => {
    if (type === "photo") {
      return (
        <div styleName="icon">
          <img src="/assetify/photo.svg" alt="photo" />
        </div>
      );
    } else if (type === "video") {
      return (
        <div styleName="icon">
          <i className="ri-movie-line"></i>
        </div>
      );
    }
  };

  useEffect(() => {
    if (typeof window === "undefined") {
      console.log("Oops, `window` is not defined")

    } else {
      const decodeURI = decodeURIComponent(window.location.href);
      if (decodeURI.includes("tallentamani-artikkelit")
        || decodeURI.includes("seuraamani-aiheet")
        || decodeURI.includes("/topic/")
        || decodeURI==='http://localhost:3000/'
        || decodeURI==='https://yhteissivusto.fiare.qtstage.io/'
        || decodeURI==='https://imediat-web.fiare.qtstage.io/'
        || decodeURI==='https://ilkkapohjalainen.fi/') {
        setIsProfilePage(true);
      }
    }
    const decodeURI = decodeURIComponent(window.location.href);
    if (decodeURI.includes("/collection/")) {
      setIsCollectionPage(true);
    }
  }, [])

  return (
    <div styleName="wrapper" className="card-image-type-5">
    <Link href={generateHref(storyObj,currentUrlSlug)}>
        <div styleName="card-image-type-5" className="card-image-type-5-card">

          {showSection && <SectionCategoryTitle name={storyObj.sections[0].name} />}
          {imgS3keys !== null && <div>
            <CardImage
              story={storyObj}
              defaultWidth={259}
              aspectRatio={[3, 2]}
              imageType="image3x2"
              widths={widths}
            />
            {(storyObj["story-template"] === "photo" || storyObj["story-template"] === "video") && (
              <div styleName="info" className="info">
                {getIcon(storyObj["story-template"])}
              </div>)}
          </div>}
        </div>
        <div className="main-headline story-content-no-img" styleName={imgS3keys ? "story-content" : "story-content-no-img"}>
          <div styleName="wrapper-inside" className="wrapper-inside">
            <h2 styleName="headline" className="headline-layout4 main-headline-layout1 layout4">
              <span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>
              {storyObj.headline}
            </h2>
          </div>
        </div>
      </Link>
      <div styleName="section-time-wrapper">
        <div className="time-stamp-layout1">
          <TimeStamp timeStamp={storyObj["published-at"]} prefix={
          isProfilePage && (paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | " || page === "tag" || page === "profile")
          ? paperValues.paperName
          : isCollectionPage
          ? paperValues.paperName
          : "" }
          />
        </div>
        {(paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ") ? null : <StarShowHide accessType={accessType} />}
        <CommentIcon storyObj={storyObj} />
        <BookmarkIcon user_flag="bm_user_flag" storyObj={storyObj} showBookmark={true} removeStory={false} />
      </div>
    </div>
  );
};

export default CardImageType7;

CardImageType7.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array,
  showSection: bool,
  showSubHeadline: bool
};
