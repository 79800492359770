import React, { useState, useRef, useEffect } from "react";
import { createUrl } from "../../utils/createurl";
import DOMPurify from "dompurify";
import "./digipaper-login-pop-up.m.css";

const DigipaperLoginModal = ({ closePopup, handleCloseModal, modalContent  }) => {
  const backgroundRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const link = createUrl("forgotpassword");

  const handleClickOutside = (event) => {
    if (backgroundRef.current && !backgroundRef.current.contains(event.target)) {
      closePopup();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleEmailChange = (e) => {
    const { value } = e.target
    const emailValue = DOMPurify.sanitize(value);
    setEmail(emailValue);
  }
  const handlePasswordChange = (e) => {
    const { value } = e.target
    const passwordValue = DOMPurify.sanitize(value);
    setPassword(passwordValue);
  }
  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(true);
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
    setPasswordIcon(!passwordIcon);
  };
  return (
    <div styleName="modal">
      <div styleName="modal-content" ref={backgroundRef}>
        <span styleName="close" onClick={handleCloseModal}>&times;</span>
        <div styleName="login-pop-up">

          {/* Jos ei ole kirjautunut, näytetään kirjautumislomake */}
          {modalContent === "not-logged-in" && (
            <React.Fragment>
              <h3>Haluatko lukea näköislehden?</h3>
              <div styleName="digilehti-cta">
                <a href="https://asiakaspalvelu.i-mediat.fi/ilkkapohjalainen/tilaa/" target="_blank" styleName="buy">
                  Tilaa tästä
                </a>
              </div>
              <br /><br />
              <p>Oletko jo tilaaja? Kirjaudu sisään</p>
              <form action="/tulo/authenticate" method="post">
                <div>
                  <div>
                    <label>
                      Käyttäjätunnus
                    </label>
                    <input styleName="digipaper-login"
                      value={email}
                      type="email"
                      name="email"
                      required
                      onChange={(e) => handleEmailChange(e)}
                    />
                  </div>
                  <div>
                    <label>
                      Salasana
                    </label>
                    <div styleName="passWd">
                      <input styleName="digipaper-login-passWd"
                        value={password}
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        required
                        onChange={(e) => handlePasswordChange(e)}
                      />
                      <a aria-label="Näytä salasana" styleName="show-passWd" role="button" onClick={togglePassword}><i className={`${passwordIcon ? "ri-eye-line" : "ri-eye-off-line"}`}></i></a>
                    </div>
                  </div>
                  <a href={link} styleName="forgot-link">Unohtuiko salasana?</a>
                  <div>
                    <input styleName="login-submit" type="submit" value="Kirjaudu" />
                  </div>
                </div>
              </form>
            </React.Fragment>
          )}
          {modalContent === "mini-product" && (
            <div>
              <h2>Ilkka-Pohjalaisen digitaaliset sisällöt</h2>
              <p>Testitilauksesi ei sisällä näköislehteä.</p>
              <br />
            </div>
          )}
          {modalContent === "logged-in" && (
          <div>
            <h3>Haluatko lukea näköislehden?</h3>
            <div styleName="digilehti-cta">
              <a href="https://asiakaspalvelu.i-mediat.fi/ilkkapohjalainen/tilaa/" target="_blank" styleName="buy">
                Tilaa tästä
              </a>
            </div>
          </div>
          )}
        </div>
      </div>

    </div>
  );
};

export default DigipaperLoginModal;
