import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { collectionToStories, Link } from "@quintype/components";
import TimeStamp from "../../atoms/time-stamp";
import { SectionCategoryTitle } from "../../molecules/section-category-title";
//import  CollectionEnd  from "../../molecules/collection-end";
import CardImageType4 from "../../molecules/card-image-type-4";
import { generateProfileHref } from "../../utils/generate-profile-href";
import "./one-big-3-small-news-rows-no-img.m.css";
import { BookmarkIcon } from "../../molecules/bookmark-icon";
import { CommentIcon } from "../../molecules/comment-icon";
import { generateHref } from "../../utils/generate-href";
import useUrl from "../../../hook/useUrl";
const OneBigThreeSmallNewsRowsNoImg = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [firstStory, ...restOftheStories] = storiesFromCollection || [];
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [_,currentUrlSlug] = useUrl();
  useEffect(() => {
    if (typeof window === "undefined") {
      console.log("Oops, `window` is not defined")

    } else {
      const decodeURI = decodeURIComponent(window.location.href);
      if (decodeURI==='http://localhost:3000/'
        || decodeURI==='https://yhteissivusto.fiare.qtstage.io/'
        || decodeURI==='https://imediat-web.fiare.qtstage.io/'
        || decodeURI==='https://ilkkapohjalainen.fi/') {
        setIsProfilePage(true);
      }
    }

  }, [])

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
            {showSection && <div><SectionCategoryTitle name={collection.name} /></div>}
            <div styleName="first-story first-story1">
              <CardImageType4 story={firstStory} showSubHeadline={false} />
            </div>
            <div styleName="item-wrapper1">
              {restOftheStories.length >= 1 &&
                restOftheStories.slice(0,3).map((item, index) =>
                    {
                      function findByKey(key, value) {
                        return (item, i) => item[key] === value
                      }
                      let findParams = findByKey("domain-slug", "ilkka-pohjalainen")
                      let positionOfPaper = item.sections.findIndex(findParams)
                      var sectionName = item.sections[positionOfPaper] ? (item.sections[positionOfPaper].name) : (item.sections[0].name)
                      return(<React.Fragment key={index}>
                      <div className="main-headline item" styleName="story-content">
                        <div styleName="wrapper-inside" className="wrapper-inside">
                          <h2 styleName="" className="headline-layout4">
                            <Link className="main-headline-layout1 layout4" href={isProfilePage ? generateProfileHref(item) : generateHref(item, currentUrlSlug)}><span styleName="span-newest">{`${sectionName} | `}</span>{item.headline}</Link>
                          </h2>
                          <div styleName="section-time-wrapper">
                            <div className="time-stamp-layout1">
                             <TimeStamp timeStamp={item["published-at"]} prefix={item.sections[0]['domain-slug'] === "vaasa" ? "Vaasa-lehti | " : item.sections[0]['domain-slug'] === "epari" ? "Epari | " : "" } />
                            </div>
                            {(item.access === "login" || item.access === "subscription") && item.sections[0]['domain-slug'] !== "vaasa" && item.sections[0]['domain-slug'] !== "epari" &&
                              <div>
                                <i className="ri-star-fill"></i>
                              </div>
                            }
                            <CommentIcon storyObj={item} />
                            <BookmarkIcon user_flag="bm_user_flag" storyObj={item} showBookmark={true} />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>)}
                  )
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

OneBigThreeSmallNewsRowsNoImg.propTypes = {
 collection: object
};

export { OneBigThreeSmallNewsRowsNoImg };

OneBigThreeSmallNewsRowsNoImg.storyLimit = 22;
