import React from "react";
import get from "lodash/get";
import { object } from "prop-types";

import { collectionToStories } from "@quintype/components";

import CardImageType4 from "../../molecules/card-image-type-4";
import CardImageType5 from "../../molecules/card-image-type-5";

import "./large-articles-preview-peach.m.css";
import { SectionCategoryTitle } from "../../molecules/section-category-title";

const LargeArticlePreviewPeach = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }
  const [firstStory, secondStory, thirdStory, fourthStory, ...restOftheStories] = storiesFromCollection || [];

  const imgS3Key = firstStory["hero-image-s3-key"]
  //console.log("imgS3Key from OneBigTwoSmallImgNewsCols", imgS3Key)
  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
          {showSection &&<div><SectionCategoryTitle name={collection.name} /></div>}
            {firstStory && <div styleName="first-story first-story1">
              <CardImageType5 story={firstStory} showSubHeadline={false} />
            </div>}
            {secondStory && <div styleName="first-story first-story1">
              <CardImageType5 story={secondStory} showSubHeadline={false} />
            </div>}
            {thirdStory && <div styleName="first-story first-story1">
              <CardImageType5 story={thirdStory} showSubHeadline={false} />
            </div>}
            {fourthStory && <div styleName="first-story first-story1">
              <CardImageType5 story={fourthStory} showSubHeadline={false} />
            </div>}

          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

LargeArticlePreviewPeach.propTypes = {
  collection: object
};
export { LargeArticlePreviewPeach };

LargeArticlePreviewPeach.storyLimit = 22;
