import React, { useState } from "react";
import { object } from "prop-types";
import get from "lodash/get";

import { SectionTag } from "@quintype/arrow";
import { collectionToStories, Link } from "@quintype/components";

import TimeStamp from "../../atoms/time-stamp";
import Star from "../../basic/images/star-fill.svg";
import StarShowHide from "../../atoms/star-show-hide";
import CardImageType6 from "../../molecules/card-image-type-6";

import "./luetuimmat.m.css";
import { postBookmark } from "../../utils/post-bookmark";
import { PopoverElement } from "../../atoms/popover-element";

const Luetuimmat = ({ collection }) => {
  //console.log("mycollections", collection)
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);

  if (storiesFromCollection.length < 1) {
    return null;
  }

  //const [firstStory, ...restOftheStories] = storiesFromCollection || [];
  const [isLogged, setIsLogged] = useState(false);
  const [op, setOp]= useState(true);

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="item-wrapper1">

            {storiesFromCollection.length >= 1 &&
              storiesFromCollection.map((item, index) =>
                <React.Fragment key={index}>
                  {item["hero-image-s3-key"] !== null ?
                    <div styleName="item" className="item" key={item.id}>
                      <CardImageType6 story={item} showSubHeadline={false} />
                    </div> :
                    <div className="main-headline item" styleName={item["hero-image-s3-key"] ? "story-content" : "story-content-no-img"}>
                      <div styleName="wrapper-inside" className="wrapper-inside">
                        <h2 styleName="" className="headline-layout4">
                          <Link className="main-headline-layout1 layout4" href={`/${item.slug}`}><Link href={`/${item.slug}`} styleName="span-newest">{item.metadata && item.metadata["pääaihe"] && `${item.metadata["pääaihe"]} | `}</Link>{item.headline}</Link>
                        </h2>
                        <div styleName="section-time-wrapper">
                          <div className="time-stamp-layout1">
                            <TimeStamp timeStamp={item["published-at"]} />
                          </div>
                          <StarShowHide accessType={item.access} />
                          <button
                            styleName="bookmark-btn"
                            onClick={(e)=>{
                            setOp(!op)
                            postBookmark(e,item.story.id, op)}
                            }>{
                              op ?
                              <PopoverElement anchor="ri-bookmark-line" />
                              :
                              <PopoverElement anchor="ri-bookmark-fill" />
                            }
                            </button>
                        </div>
                      </div>
                    </div>
                  }
                </React.Fragment>
              )
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Luetuimmat.propTypes = {
  collection: object
};

export { Luetuimmat };

Luetuimmat.storyLimit = 22;
