import React, { useState, useEffect } from "react";
import "./tulo-login.m.css";
import { GooglePlayBadge } from "../../atoms/google-play-badge";
import { AppStoreBadge } from "../../atoms/app-store-badge";
import useStorage from "../../../hook/useStorage";
import { IPiltaExtra } from "../../atoms/ip-iltaextra";
import { Suurtaulu } from "../../atoms/suurtaulu";
import useUrl from "../../../hook/useUrl";
import DigipaperLoginModal from "../../atoms/digipaper-login-pop-up";

const TuloLogin = () => {

  const [checkUser, activeProducts, whiteListed] = useStorage();
  const [isMiniProductActive, setIsMiniProductActive] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [, currentUrlSlug] = useUrl();

  useEffect(() => {
    let isMounted = true;

    const timer1 = setTimeout(() => {
      if (isMounted) { // check if component is still mounted
        setIsDisplayed(true);
      }
    }, !checkUser ? 1000 : 0);

    const timer2 = setTimeout(() => {
      if (typeof window !== 'undefined' && isMounted) { // check if component is still mounted
        const miniProductActive = JSON.parse(localStorage.getItem("miniProduct"));
        setIsMiniProductActive(miniProductActive);
      }
    }, 720);

    return () => {
      isMounted = false;
      clearTimeout(timer1); // clear the timeout
      clearTimeout(timer2); // clear the timeout
    };
  }, []);


  const [showModal, setShowModal] = useState(false);
  const togglePopup = (isPopupOpen) => {
    setShowModal(isPopupOpen);
  }
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div styleName="top-container">
      <div styleName="container">
        <div styleName="main">
          <div styleName="ad-container">
            {/* PARAATI mobiili*/}
            <div styleName="mob-ad">
              <div id="sas_63333" data-placement-id="paraatipaikka-mobile"></div>
            </div>
            {/* PARAATI deskari ja tabletti*/}
            <div styleName="dsktop-ad">
              <div id="sas_63219" data-placement-id="paraatipaikka-desktop"></div>
            </div>
          </div>
        </div>

        <div styleName="lazy-pop">
          <div styleName="digilehti-content">
            <div>
              {((checkUser && (activeProducts && !isMiniProductActive) || whiteListed) || (currentUrlSlug === "vaasalehti" || currentUrlSlug === "epari")) &&
                (() => {
                  switch (currentUrlSlug) {
                    case "vaasalehti":
                      return (
                        <a href="https://vaasanikkuna.e-pages.pub/" target="_blank" >
                          <figure styleName="digipaper-free">
                            <img styleName="digipaper" src="https://www.e-pages.dk/vaasanikkuna/teasers/medium.jpg" border="0" width="300" />
                          </figure>
                        </a>
                      );
                    case "epari":
                      return (
                        <a href="https://epari.e-pages.pub/" target="_blank" >
                          <figure styleName="digipaper-free">
                            <img styleName="digipaper" src="https://www.e-pages.dk/epari/teasers/medium.jpg" width="300" border="0" />
                          </figure>
                        </a>
                      );
                    default:
                      return (
                        <a href="https://digilehti.ilkkapohjalainen.fi/">
                          <figure>
                            <img styleName="digipaper"
                              src="https://www.e-pages.dk/ilkkapohjalainen/teasers/11846/medium.jpg"
                              border="0"
                              width="300"
                            />
                          </figure>
                        </a>
                      );
                  }
                })()
              }
              {/* activeProducts on tosi, jos on mikä tahansa tilaus, mutta minituote ei voi olla tosi jos on joku muu tilaus.
              jos activeProducts on epätosi tai minituote on tosi*/}

              {(((checkUser && (!activeProducts || isMiniProductActive)) || (!checkUser && !whiteListed)) && (currentUrlSlug !== "vaasalehti" && currentUrlSlug !== "epari")) &&
                <button onClick={handleOpenModal}>
                  <figure styleName="digipaper-non-subscriber">
                    <img styleName="digipaper" src="https://www.e-pages.dk/ilkkapohjalainen/teasers/11846/medium.jpg" border="0" width="300" />
                  </figure>
                </button>
              }
              {/*<IPiltaExtra />*/}
            </div>
            <div>
              <div id="yleisolle">
                <h2>Näköislehdet</h2>
                <p>
                  Näköislehti on painetun lehden digitaalinen kopio. Ilkka-Pohjalaisen näköislehti on luettavissa edellisenä
                  iltana noin klo 23. Kaupunkilehtien näköislehdet ilmestyvät julkaisupäivää edeltävänä yönä noin klo 01. </p>
                <p>Lukeaksesi Ilkka-Pohjalaisen näköislehteä sinulla tulee olla I-Mediatili ja voimassa oleva tilaus.
                  Kaupunkilehtien näköislehdet ovat vapaasti luettavissa.
                </p>
              </div>
              <div styleName="app-badges">
                <GooglePlayBadge />
                <AppStoreBadge />
              </div>
            </div>

            {showModal && (
              <>
                {(!checkUser && isDisplayed) && (
                  <DigipaperLoginModal
                    closePopup={() => togglePopup()}
                    handleCloseModal={handleCloseModal}
                    modalContent="not-logged-in"
                  />
                )}
                {(isMiniProductActive && isDisplayed) && (
                  <DigipaperLoginModal
                    closePopup={() => togglePopup()}
                    handleCloseModal={handleCloseModal}
                    modalContent="mini-product"
                  />
                )}
                {(!whiteListed && (checkUser && !activeProducts) && isDisplayed) && (
                  <DigipaperLoginModal
                    closePopup={() => togglePopup()}
                    handleCloseModal={handleCloseModal}
                    modalContent="logged-in"
                  />
                )}
              </>
            )}
          </div>
          <div styleName="digipaper-list-small">
            {((checkUser && (activeProducts && !isMiniProductActive) || whiteListed) && (currentUrlSlug === "vaasalehti" || currentUrlSlug === "epari")) &&
              <div styleName="small-publication">
                <a href="https://digilehti.ilkkapohjalainen.fi/" target="_blank">
                  <figure>
                    <img styleName="digipaper" src="https://www.e-pages.dk/ilkkapohjalainen/teasers/11846/medium.jpg" border="0" />
                  </figure>
                </a>
              </div>
            }
            {(((checkUser && (!activeProducts || isMiniProductActive)) || (!checkUser && !whiteListed)) && (currentUrlSlug === "vaasalehti" || currentUrlSlug === "epari")) &&
              <button onClick={handleOpenModal}>
                <figure styleName="digipaper-non-subscriber small-publication">
                  <img styleName="digipaper" src="https://www.e-pages.dk/ilkkapohjalainen/teasers/11846/medium.jpg" border="0" />
                </figure>
              </button>
            }
            {(currentUrlSlug !== "epari") &&
              <div styleName="small-publication">
                <a href="https://epari.e-pages.pub/" target="_blank" >
                  <figure styleName="digipaper-free">
                    <img styleName="digipaper" src="https://www.e-pages.dk/epari/teasers/medium.jpg" border="0" />
                  </figure>
                </a>
              </div>
            }
            {(currentUrlSlug !== "vaasalehti") &&
              <div styleName="small-publication">
                <a href="https://vaasanikkuna.e-pages.pub/" target="_blank" >
                  <figure styleName="digipaper-free">
                    <img styleName="digipaper" src="https://www.e-pages.dk/vaasanikkuna/teasers/medium.jpg" border="0" />
                  </figure>
                </a>
              </div>
            }
          </div>
        </div>
      </div>
      <Suurtaulu />
    </div >
  );
}
export { TuloLogin };
