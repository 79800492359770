import React, { useEffect } from "react";

const Vaalitulokset = () => {

  //toisen kerroksen src: https://tulospalvelu.vaalit.almamedia.fi/tpv-2024-2/tulospalvelu.js
  useEffect(() => {
    const tag = document.createElement("script");
    tag.src = "https://tulospalvelu.vaalit.almamedia.fi/tpv-2024-1/tulospalvelu.js";
    tag.async = true;
    document.body.appendChild(tag);
    window.disableAjaxNavigation = false;
  }, []);

  return (
    <>
      <div id="alma-tulospalvelu-app" />
    </>
  );
};
export { Vaalitulokset };
