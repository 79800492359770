import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { collectionToStories } from "@quintype/components";
import { StoryCard } from "@quintype/arrow";

import CardImageType2 from "../../molecules/card-image-type-2";
import CardImageType1 from "../../molecules/card-image-type-1";
import CardImageType3 from "../../molecules/card-image-type-3";

import "./one-col-four-stories.m.css";
const OneColFourStories = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [firstStory, ...restOftheStories] = storiesFromCollection || [];

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
            <div styleName="first-story first-story1">
              <CardImageType3 story={firstStory} showSection />
            </div>
            <div styleName="item-wrapper1">

              {restOftheStories.length >= 1 &&
                restOftheStories.slice(0,3).map((item, index) =>
                  <React.Fragment key={index}>
                    <div styleName="item" key={item.id}>
                      <CardImageType1 story={item} />
                    </div>
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

OneColFourStories.propTypes = {
  collection: object
};

export { OneColFourStories };

OneColFourStories.storyLimit = 12;
