import React from "react";
import { object } from "prop-types";
import Carousel from "./carousel"
import CardImageType1 from "../card-image-type-1";

import "./carousel-slider.m.css";

const CarouselSlider = ({ collection, options }) => {
  const storiesFromCollection = collection.items.filter(item => item.type === "story");
  const children = storiesFromCollection.map((story, key) => {
    const storyObj = story.story ? story.story : story;
    return <div key={key}>{story && <CardImageType1 story={storyObj} headlineLimit={70} />}</div>;
  });

  return (
    <div styleName="carousel-container">
      <Carousel
        carouselName={`carousel-${collection.slug}`}
        options={options}
        totalItems={storiesFromCollection.length}
        showArrows={true}
        renderCenterLeftControls={({ previousSlide, currentSlide }) => {
          return (
            currentSlide !== 0 && (
              <button onClick={previousSlide} className="slider-btn">
               <img src="/assetify/left-arrow.svg" alt="left" />
              </button>
            )
          );
        }}
        renderCenterRightControls={({ nextSlide }) =>{
          return (
            storiesFromCollection.length >= 4 && (
              <button onClick={nextSlide} className="slider-btn">
              <img src="/assetify/right-arrow.svg" alt="right" />
            </button>
            )
          );
        }
        }
      >
        {children}
      </Carousel>
    </div>
  );
};

export default CarouselSlider;

CarouselSlider.propTypes = {
  collection: object,
  options: object
};
