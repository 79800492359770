import { Headline, HeroImage, SectionTag, StoryCard } from "@quintype/arrow";
import { LoadMoreCollectionStories, ResponsiveImage } from "@quintype/components";
import get from "lodash/get";
import { array, bool, func, object } from "prop-types";
import React from "react";
import { LoadmoreButton } from "../../atoms/Loadmore-btn";

import "./author.m.css";

const AuthorPage = ({ data }) => {
  const { author } = data;
  const authorAvatarUrl = get(data, ["author", "avatar-url"]);
  const avatarURL = get(data, ["author", "avatar-s3-key"]);
  let imageView = null;
  const storyObj = {
    "hero-image-s3-key": avatarURL,
    "hero-image-metadata": "",
    "hero-image-caption": "Author"
  };
  if (avatarURL) {
    imageView = (
      <figure styleName="figure-wrapper">
        <ResponsiveImage
          slug={storyObj["hero-image-s3-key"]}
          metadata={storyObj["hero-image-metadata"]}
          aspectRatio={[1, 1]}
          alt={storyObj["hero-image-caption"]}
          defaultWidth={240}
          widths={[240, 120]}
          styleName="image"
        />
      </figure>
    );
  } else if (authorAvatarUrl) {
    imageView = <img src={authorAvatarUrl} alt={author.name} styleName="user" />;
  } else {
    imageView = <img src="/assetify/user.svg" alt={author.name} />;
  }

  const storiesDom = stories => {
    return stories.map((item, index) => {
      const storyObj = item.story || item;
      return (
        <div styleName="story-card" key={index}>
          <StoryCard story={storyObj} isHorizontal>
            <HeroImage
              story={storyObj}
              isHorizontal
              aspectRatio={[
                [1, 1],
                [16, 9]
              ]}
            />
            <div>
              <SectionTag story={storyObj} />
              <Headline story={storyObj} headerLevel="4" />
            </div>
          </StoryCard>
        </div>
      );
    });
  };

  const storiesTemplate = ({ onLoadMore, stories, noMoreStories }) => (
    <div>
      {storiesDom(stories, noMoreStories, onLoadMore)}
      {!noMoreStories && stories.length >= 20 && <LoadmoreButton onClick={onLoadMore} btnText="NÄYTÄ LISÄÄ" />}
    </div>
  );

  const authorBio = author => (
    <div styleName="author-wrapper">
      <div styleName="author-bio">
        <div styleName="author-img">{imageView}</div>
        <div styleName="info">
          {author.name && <h1 styleName="name">{author.name}</h1>}
          <div dangerouslySetInnerHTML={{ __html: author.bio }} />
          {author["twitter-handle"] && (
            <a
              styleName="share"
              href={`https://www.twitter.com/${author["twitter-handle"]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assetify/twitter.svg" alt="twitter" width="30" />
              <span>@{`${author["twitter-handle"]}`}</span>
            </a>
          )}
        </div>
      </div>
      {/*<div styleName="dfp-ad">
        <DfpAd adtype="xxxx" slotId="authorBio" />
      </div>*/}
    </div>
  );
  return (
    <div styleName="wrapper">
      {authorBio(author)}
      <div styleName="author-body">
        <LoadMoreCollectionStories
          template={storiesTemplate}
          data={{ stories: data.stories }}
          authorId={data.author.id}
          numStoriesToLoad={10}
        />
      </div>
    </div>
  );
};

AuthorPage.propTypes = {
  data: object,
  onLoadMore: func,
  stories: array,
  noMoreStories: bool
};

export { AuthorPage };
