import React from "react";
import { WithLazy, wrapCollectionLayout, EagerLoadImages } from "@quintype/components";
import { FourColGridRow } from "../rows/four-col-grid";
import { StoryCardSlider } from "../rows/story-card-slider";
import { FullScreenSliderRow } from "../rows/full-screen-slider-row";
import { ThreeColFifteenStoriesRow } from "../rows/three-col-fifteen-stories-row";
import { TwoColWithSevenStoriesRow } from "../rows/two-col-with-seven-stories-row";
import { FeatureSliderRow } from "../rows/feature-slider";
import { OpinionPolls } from "../rows/opinion-polls";
import { OneColFourStories } from "../rows/one-col-four-stories";
import { FourColFourRowsTenStories } from "../rows/four-col-four-rows-ten-stories";
import { LargeNewsImgCard } from "../rows/large-news-img-card";
import { TwoColFourStoriesRow } from "../rows/two-col-four-stories-row";
import { NoImgNewsCards } from "../rows/no-image-news-cards";
import { TwoRowsThreeCols } from "../rows/two-rows-three-cols";
import { NativeAdLayout } from "../rows/native-ad-layout";
import { SmallImgNewsCards } from "../rows/small-image-news-cards";
import { ThreeSmallNewsCardImg } from "../rows/three-small-news-cards-with-img";
import { OneColFourStoriesNoAd } from "../rows/one-col-4-stories-no-ad";
import { OneBigThreeSmallImgNewsRows } from "../rows/one-big-3-small-img-news-rows";
import { OneBigTwoSmallImgNewsCols } from "../rows/one-big-2-small-img-news-cols";
import { OneBigThreeSmallImgNewsCols } from "../rows/one-big-3-small-img-news-cols";
import { OneBigThreeSmallNewsRowsNoImg } from "../rows/one-big-3-small-news-rows-no-img";
import { SixSmallNewsImgNHeadline } from "../rows/six-small-news-img-headline";
import { OneBigThreeSmallImgNewsColsWithPointti } from "../rows/one-big-3-small-img-news-cols-with-pointtinosto";
import { LargeArticlePreview } from "../rows/large-articles-preview";
import { FourSmallImgNewsCols } from "../rows/four-small-image-news-cols";
import { FiveSmallImgNewsRows } from "../rows/five-small-img-news-rows";
import { BigImagesWithAds } from "../rows/big-images-with-ads";
import { BlackAndWhite } from "../rows/black-white";
import { OneBigThreeSmallImgNewsColsPeach } from "../rows/one-big-3-small-img-news-cols-peach";
import { LargeArticlePreviewPeach } from "../rows/large-articles-preview-peach";
import { OneBigThreeSmallImgNewsColsYellow } from "../rows/one-big-3-small-img-news-cols-yellow";
import { LargeArticlePreviewYellow } from "../rows/large-articles-preview-yellow";
import { OneBigThreeSmallImgNewsColsPurple } from "../rows/one-big-3-small-img-news-cols-purple";
import { LargeArticlePreviewPurple } from "../rows/large-articles-preview-purple";
import { OneBigSixSmallImgNewsCols } from "../rows/one-big-6-small-img-news-cols";
import Suosituimmat from "../story-templates/suosituimmat";
import { Luetuimmat } from "../rows/luetuimmat";
import { RightNow } from "../rows/right-now";
import Pointti from "../pointti";
import Games from "../games";
import { Readpeak } from "../atoms/readpeak";
// import VideoList from "../story-templates/video-list";
import ChartbeatStories from "../story-templates/chartbeat-stories";
import { Podcast } from "../atoms/podcast";

import "./collection-templates.m.css";

function wrapEager(f) {
  const wrappedComponent = function WrapEager(props) {
    if (props.index === 0) {
      return (
        <>
        <EagerLoadImages predicate={token => token === "above-fold"}>{React.createElement(f, props)}</EagerLoadImages>
            {/* Ad position 2 - Pystyparaati mobiili JA TABLETTI*/}
            {props.index === 0 && <div styleName="indexes pystyparaati" data-lazy-ad-unit-id="sas_63337" data-placement-id="2.mainospaikka-mobile"></div>}
            {/* Ad position 2B - Pystyparaati mobiili JA TABLETTI*/}
            {props.index === 0 && <div styleName="indexes pystyparaati" data-lazy-ad-unit-id="sas_120817" data-placement-id="2a.mainospaikka-mobile"></div>}
            {/* Video-list */}
            {/*props.index === 0 && <div styleName="video-list">{<VideoList />}</div>*/}
            {/* Luetuimmat-list from Chartbeat */}
            {props.index === 0 && <div styleName="suosituimmat">{<ChartbeatStories />}</div>}
            {/* Ad position 3 - Mainospaikka 1 mobile */}
            {props.index === 0 && <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_106330" data-placement-id="mainospaikka-1-uutisvirta-mobile"></div>}
            {/* Ad position 3 - Mainospaikka 1 */}
            {props.index === 0 && <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_106329" data-placement-id="mainospaikka-1-uutisvirta-desktop"></div>}
        </>
      );
    } else {
      return (
        <>
          {React.createElement(f, props)}
          {/* Readpeak */}
          {props.index === 1 && <div styleName="readpeak-widget"><Readpeak/></div>}
          {/* Native ad 1 */}
          {props.index === 1 && <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_63231" data-placement-id="natiivimainos-1-desktop"></div>}
          {props.index === 1 && <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_63352" data-placement-id="natiivimainos-1-mobile"></div>}
          {/* Mainospaikka 1B */}
          {props.index === 1 && <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_113458" data-placement-id="mainospaikka-1b-uutisvirta-desktop"></div>}
          {props.index === 1 && <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_113459" data-placement-id="mainospaikka-1b-uutisvirta-mobile"></div>}
          {/* Ad position 4 - Mainospaikka 2 */}
          {props.index === 2 && <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_106331" data-placement-id="mainospaikka-2-uutisvirta-desktop"></div>}
          {props.index === 2 && <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_106332" data-placement-id="mainospaikka-2-uutisvirta-mobile"></div>}
          {/* Games */}
          {props.index === 2 && <div styleName="games">{<Games/>}</div>}
          {/* Pointti */}
          {props.index === 2 && <div styleName="indexes pointti-ad" className="pointti-ad">
            <WithLazy margin="50px">{() =>
              <Pointti/>
            }</WithLazy></div>}
          {/* Mainospaikka 2B mobile */}
          {props.index === 2 && <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_113461" data-placement-id="2B-mainospaikka-mobile"></div>}
          {/* Mainospaikka 2C mobile */}
          {props.index === 3 && <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_129941" data-placement-id="2C-mainospaikka-mobile"></div>}
          {/* Native ad 2 */}
          {props.index === 3 && <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_63232" data-placement-id="natiivimainos-2-desktop"></div>}
          {props.index === 3 && <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_63353" data-placement-id="natiivimainos-2-mobile"></div>}
          {/* Oma mainos mobile */}
          {/*props.index === 3 && <div styleName="podcast-banner">{<Podcast/>}</div>*/}
          {/* Ad position 5 - Mainospaikka 3 */}
          {props.index === 4 && <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_106333" data-placement-id="mainospaikka-3-uutisvirta-desktop"></div>}
          {props.index === 4 && <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_106334" data-placement-id="mainospaikka-3-uutisvirta-mobile"></div>}
          {/* Native ad 3 */}
          {props.index === 5 && <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_63233" data-placement-id="natiivimainos-3-desktop"></div>}
          {props.index === 5 && <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_63354" data-placement-id="natiivimainos-3-mobile"></div>}
          {/* Ad position 5 - Mainospaikka 4 */}
          {props.index === 6 && <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_106335" data-placement-id="mainospaikka-4-uutisvirta-desktop"></div>}
          {props.index === 6 && <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_106336" data-placement-id="mainospaikka-4-uutisvirta-mobile"></div>}
          {props.index === 6 && (
            <div styleName="indexes dsktop-ad" className="gam_dt">
              {/* GAM test /23207017768/Mp4UVdt */}
              <div id="div-gpt-ad-1731073323595-0">
                <div dangerouslySetInnerHTML={{__html: `<script>googletag.cmd.push(function() { googletag.display('div-gpt-ad-1731073323595-0'); });</script>`}} />
              </div>
            </div>
            )}
          {props.index === 6 && (
            <div styleName="indexes mob-ad" className="gam_mob">
              {/* GAM test /23207017768/Mp4UVmob */}
              <div id="div-gpt-ad-1731073453179-0">
                <div dangerouslySetInnerHTML={{__html: `<script>googletag.cmd.push(function() { googletag.display('div-gpt-ad-1731073453179-0'); });</script>`}} />
              </div>
            </div>
            )}
        </>
      )
    }
  };

  if (f.storyLimit) {
    wrappedComponent.storyLimit = f.storyLimit;
  }

  return wrappedComponent;
}

export default {

  TwoColWithSevenStoriesRow: wrapEager(wrapCollectionLayout(TwoColWithSevenStoriesRow)),
  TwoRowsThreeCols: wrapEager(wrapCollectionLayout(TwoRowsThreeCols)),
  TwoColFourStoriesRow: wrapEager(wrapCollectionLayout(TwoColFourStoriesRow)),
  ThreeColFifteenStoriesRow: wrapEager(wrapCollectionLayout(ThreeColFifteenStoriesRow)),
  OpinionPolls: wrapEager(wrapCollectionLayout(OpinionPolls)),
  FourColFourRowsTenStories: wrapEager(wrapCollectionLayout(FourColFourRowsTenStories)),
  FourColGridRow: wrapEager(wrapCollectionLayout(FourColGridRow)),
  FullScreenSliderRow: wrapEager(wrapCollectionLayout(FullScreenSliderRow)),
  FeatureSliderRow: wrapEager(wrapCollectionLayout(FeatureSliderRow)),
  StoryCardSlider: wrapEager(wrapCollectionLayout(StoryCardSlider)),
  LargeNewsImgCard: wrapEager(wrapCollectionLayout(LargeNewsImgCard)),
  ThreeSmallNewsCardImg: wrapEager(wrapCollectionLayout(ThreeSmallNewsCardImg)),
  OneColFourStories: wrapEager(wrapCollectionLayout(OneColFourStories)),
  OneColFourStoriesNoAd: wrapEager(wrapCollectionLayout(OneColFourStoriesNoAd)),
  SmallImgNewsCards: wrapEager(wrapCollectionLayout(SmallImgNewsCards)),
  NoImgNewsCards: wrapEager(wrapCollectionLayout(NoImgNewsCards)),
  NativeAdLayout: wrapEager(wrapCollectionLayout(NativeAdLayout)),
  OneBigThreeSmallImgNewsRows: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsRows)),
  OneBigTwoSmallImgNewsCols: wrapEager(wrapCollectionLayout(OneBigTwoSmallImgNewsCols)),
  OneBigThreeSmallImgNewsCols: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsCols)),
  OneBigThreeSmallImgNewsColsPeach: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsColsPeach)),
  OneBigThreeSmallImgNewsColsYellow: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsColsYellow)),
  OneBigThreeSmallImgNewsColsPurple: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsColsPurple)),
  OneBigThreeSmallNewsRowsNoImg: wrapEager(wrapCollectionLayout(OneBigThreeSmallNewsRowsNoImg)),
  SixSmallNewsImgNHeadline: wrapEager(wrapCollectionLayout(SixSmallNewsImgNHeadline)),
  LargeArticlePreview: wrapEager(wrapCollectionLayout(LargeArticlePreview)),
  FourSmallImgNewsCols: wrapEager(wrapCollectionLayout(FourSmallImgNewsCols)),
  FiveSmallImgNewsRows: wrapEager(wrapCollectionLayout(FiveSmallImgNewsRows)),
  RightNow: wrapEager(wrapCollectionLayout(RightNow)),
  OneBigThreeSmallImgNewsColsWithPointti: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsColsWithPointti)),
  Luetuimmat: wrapEager(wrapCollectionLayout(Luetuimmat)),
  BigImagesWithAds: (wrapCollectionLayout(BigImagesWithAds)),
  BlackAndWhite: wrapEager(wrapCollectionLayout(BlackAndWhite)),
  defaultTemplate: wrapEager(wrapCollectionLayout(LargeArticlePreview)),
  LargeArticlePreviewPeach: wrapEager(wrapCollectionLayout(LargeArticlePreviewPeach)),
  LargeArticlePreviewYellow: wrapEager(wrapCollectionLayout(LargeArticlePreviewYellow)),
  LargeArticlePreviewPurple: wrapEager(wrapCollectionLayout(LargeArticlePreviewPurple)),
  OneBigSixSmallImgNewsCols: wrapEager(wrapCollectionLayout(OneBigSixSmallImgNewsCols))
};
