import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { SectionTag } from "@quintype/arrow";
import { collectionToStories } from "@quintype/components";
import Suosituimmat from "../../story-templates/suosituimmat";
import IlkkaPohjalainenList from "../../story-templates/ilkka-pohjalainen-list";
import "./big-images-with-ads.m.css";
import CardImageType5 from "../../molecules/card-image-type-5";
import Pointti from "../../pointti";
import { Readpeak } from "../../atoms/readpeak";

const storiesToBigImagesWithAds = storiesFromCollection => {
  return storiesFromCollection.slice(0, 17).map((item, index) => {
    return (
      <React.Fragment key={index}>
        <div styleName="desktop-ad-slots">
          {index === 3 && (
            <div styleName="ad-container-between-articles">
               {/* Ad position 2 - Pystyparaati mobiili JA TABLETTI*/}
              <div styleName="indexes pystyparaati" data-lazy-ad-unit-id="sas_63337" className="2.mainospaikka-mobile"></div>
             {/* Ad position 2B - Pystyparaati mobiili JA TABLETTI*/}
             <div styleName="indexes pystyparaati" data-lazy-ad-unit-id="sas_120817" className="2a.mainospaikka-mobile"></div>
              {/* Ad position 3 - Mainospaikka 1 */}
              <div styleName="indexes dsktop-ad"  data-lazy-ad-unit-id="sas_106329"className="mainospaikka-1-uutisvirta-desktop"></div>
               {/* Ad position 3 - Mainospaikka 1 mobile */}
              <div styleName="indexes mob-ad"  data-lazy-ad-unit-id="sas_106330"className="mainospaikka-1-uutisvirta-mobile"></div>
            </div>
          )}
          {/* Ilkka-Pohjalainen-list */}
          {index === 2 && <div styleName="ilkka-pohjalainen-list">{<IlkkaPohjalainenList />}</div>}
          {index === 5 && (
            <div styleName="ad-container-between-articles">
              {/* Readpeak */}
              <div styleName="readpeak-widget"><Readpeak/></div>
              {/* Native ad 1 */}
              <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_63231" className="natiivimainos-1-desktop"></div>
              <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_63352" className="natiivimainos-1-mobile"></div>
              {/* Mainospaikka 1B */}
              <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_113458" className="mainospaikka-1b-uutisvirta-desktop"></div>
              <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_113459" className="mainospaikka-1b-uutisvirta-mobile"></div>
            </div>
          )}
          {index === 7 && (
            <div styleName="ad-container-between-articles">
              {/* Mainospaikka 2 */}
              <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_106331" className="mainospaikka-2-uutisvirta-desktop"></div>
              <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_106332" className="mainospaikka-2-uutisvirta-mobile"></div>
              <div styleName="pointti-ad indexes">
                <Pointti />
              </div>
              {/* Mainospaikka 2B mobile */}
              <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_113461" className="2B-mainospaikka-mobile"></div>
            </div>
          )}
          {index === 9 && (
            <div styleName="ad-container-between-articles">
              {/* Mainospaikka 2C mobile */}
              <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_129941" className="2C-mainospaikka-mobile"></div>
              {/* Native ad 2 */}
              <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_63232" className="natiivimainos-2-desktop"></div>
              <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_63353" className="natiivimainos-2-mobile"></div>
            </div>
          )}
          {index === 11 && (
            <div styleName="ad-container-between-articles">
              {/* Mainospaikka 3 */}
              <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_106333" className="mainospaikka-3-uutisvirta-desktop"></div>
              <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_106334" className="mainospaikka-3-uutisvirta-mobile"></div>
            </div>
          )}
          {index === 13 && (
            <div styleName="ad-container-between-articles">
              {/* Native ad 3 */}
              <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_63233" className="natiivimainos-3-desktop"></div>
              <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_63354" className="natiivimainos-3-mobile"></div>
            </div>
          )}
          {index === 15 && (
            <div styleName="ad-container-between-articles">
              {/* Mainospaikka 4 */}
              <div styleName="indexes dsktop-ad" data-lazy-ad-unit-id="sas_106335" className="mainospaikka-4-uutisvirta-desktop"></div>
              <div styleName="indexes mob-ad" data-lazy-ad-unit-id="sas_106336" className="mainospaikka-4-uutisvirta-mobile"></div>
            </div>
          )}
        </div>
        <div styleName="item-wrapper1">
          <React.Fragment key={index}>
            <div styleName="item" key={item.id}>
              <CardImageType5 story={item} showSubHeadline={false} />
            </div>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  });
};

const BigImagesWithAds = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);

  if (storiesFromCollection.length < 1) {
    return null;
  } else {
    return (
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">{storiesToBigImagesWithAds(storiesFromCollection)}</div>
        </div>
      </div>
    );
  }
};

BigImagesWithAds.propTypes = {
  collection: object
};

export { BigImagesWithAds };

BigImagesWithAds.storyLimit = 17;
