/* eslint-disable import/extensions */
import { bool, number, object, shape } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { LazyCollection, LazyLoadImages } from "@quintype/components";
import { getElectionCollectionTemplate } from "../../get-collection-template";
import "./elections.m.css";
import { Vaalikone } from "../../atoms/vaalikone";

const Elections = ({ data, reloadTimeInMinutes = 15, shouldReload = false }) => {

  useEffect(() => {
    if (!shouldReload) return;
    let timeElapsed = 0;
    let timerId;

    const Main = () => {
      if (document.hidden && timeElapsed === 0) {
        startTimer();
      }
      if (!document.hidden && timeElapsed > 0 && timeElapsed < reloadTimeInMinutes) {
        clearInterval(timerId);
        timeElapsed = 0;
      }
      if (!document.hidden && timeElapsed >= reloadTimeInMinutes) {
        window.location.reload();
      }
    };

    function updateTimeElapsed() {
      timeElapsed = timeElapsed + 1;
    }
    function startTimer() {
      timerId = window.setInterval(updateTimeElapsed, 60000);
    }
    document.addEventListener("visibilitychange", Main);
    return () => {
      document.removeEventListener("visibilitychange", Main);
    };
  }, []);


  return (
    <React.Fragment>
      <div styleName="elections-top-container">
        <div styleName="container">
          <div styleName="lazy-pop">
            <div styleName="lazy-load">
              <Vaalikone />
              <LazyLoadImages>
                <LazyCollection collection={data.collection} collectionTemplates={getElectionCollectionTemplate} lazyAfter={22} />
              </LazyLoadImages>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Elections.propTypes = {
  data: shape({
    collection: object
  }),
  reloadTimeInMinutes: number,
  shouldReload: bool
};

function mapStateToProps(state) {
  return {
    shouldReload: get(state, ["qt", "config", "publisher-attributes", "shouldReload"], false),
    reloadTimeInMinutes: Number(get(state, ["qt", "config", "publisher-attributes", "reloadTimeInMinutes"], 15))
  };
}

export const ElectionPage = connect(mapStateToProps, () => ({}))(Elections);
