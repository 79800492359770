import React, { useState } from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { SectionCategoryTitle } from "../../molecules/section-category-title";
//import CollectionEnd  from "../../molecules/collection-end";
import { collectionToStories, Link } from "@quintype/components";
import CardImageType8 from "../../molecules/card-image-type-8";
import CardImageType4 from "../../molecules/card-image-type-4";

import "./black-white.m.css";

const BlackAndWhite = ({ collection }) => {
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }

  const [firstStory, ...restOftheStories] = storiesFromCollection || [];

  return (
    <React.Fragment>
      <div styleName="wrapper dark-bg">
        <div styleName="container">
          <div styleName="inner-wrapper1">
            {showSection && <div><SectionCategoryTitle name={collection.name} /></div>}
            <div styleName="first-story first-story1">
              <CardImageType4 story={firstStory} showSubHeadline={false} />
            </div>
            <div styleName="item-wrapper1">
              {restOftheStories.length >= 1 &&
                restOftheStories.slice(0, 3).map((item, index) =>
                  <React.Fragment key={index}>
                      <div styleName="item" className="item" key={item.id}>
                        <CardImageType8 story={item} showSubHeadline={false} />
                      </div>
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

BlackAndWhite.propTypes = {
  collection: object
};

export { BlackAndWhite };

BlackAndWhite.storyLimit = 22;
