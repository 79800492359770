export function generateProfileHref(storyObj) {

  var storysCurrentUrlSlug = "";

  // Check what of paper is selected first to story and then use that in CurrentUrlSlug for deciding in what papers page it needs to be shown.
  // Set accesstype to public if fist selected section for the story is vaasa or epari.
  // Set paper name shown before timestamp based on what section is selected first for a story.
  const inVaasa = storyObj.sections.findIndex(section => section["domain-slug"] === "vaasa");
  const inEpari = storyObj.sections.findIndex(section => section["domain-slug"] === "epari");
  const inIP = storyObj.sections.findIndex(section => section["domain-slug"] === "ilkka-pohjalainen");
  const indices = [inVaasa, inEpari, inIP].filter(index => index !== -1);
  const smallestIndex = Math.min(...indices);
  var domainSlug = "";
  if (inEpari === smallestIndex && inEpari !== -1) {
    storysCurrentUrlSlug = "epari"
    domainSlug = "epari";
  } else if (inVaasa === smallestIndex && inVaasa !== -1) {
    storysCurrentUrlSlug = "vaasalehti"
    domainSlug = "vaasa";
  } else if (inIP === smallestIndex && inIP !== -1) {
    storysCurrentUrlSlug = ""
    domainSlug = "ilkka-pohjalainen";
  }
  function findByKey(key, value) {
    return (item, i) => item[key] === value;
  }

  const findParams = findByKey("domain-slug", domainSlug);
  const positionOfPaper = storyObj.sections.findIndex(findParams);
  const parts = storyObj.slug.lastIndexOf("/");
  const storySlug = storyObj.slug.substring(parts + 1);
  let finalUrl = "";
  if (storyObj.sections[positionOfPaper]) {
    const splitUrl = storyObj.sections[positionOfPaper]["section-url"].split("/");
    const joinUrl = splitUrl.slice(3);
    finalUrl = joinUrl.join("/");
  }
  return (positionOfPaper !== -1 && domainSlug === "ilkka-pohjalainen" ? `/${finalUrl}/${storySlug}` : `/${storysCurrentUrlSlug}/${finalUrl}/${storySlug}`
  )
}
