import React, { useEffect, useState } from "react";
import { object } from "prop-types";
import axios from "axios";
import LoginFormBase from "../../atoms/login-forms/login-form";
import { Newsletter } from "../../atoms/newsletter";
import useStorage from "../../../hook/useStorage";
import SavedStories from "./saved-stories";
import SavedTaggedStories from "./saved-tagged-stories";
import ProfileInfo from "./profile-info";
import Tags from "../../molecules/tags";
import { postTags } from "../../utils/post-tags";
import "./profile-page.m.css";
import { PopoverElement } from "../../atoms/popover-element";

const MemoizedSavedStories = React.memo(SavedStories);
const MemoizedSavedTaggedStories = React.memo(SavedTaggedStories);
const ProfilePage = React.memo(({ config }) => {
  const [storyIds, setStoryIds] = useState([]);
  const [tagNames, setTagnames] = useState([]);
  const [checkUser] = useStorage();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [wListed, setWlisted] = useState(false);
  const [profilePageSlug, setProfilePageSlug] = useState();
  const [hideTags, setHideTags] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);

  const deleteAllTags = (e, names) => {
    if (typeof window !== 'undefined') {
      names.map(tNames => postTags(e, tNames.name, false));
      setHideTags(true);
    }
  }

  /** fetch all story ids */
  const fetchStoryIds = async () => {
    const uri = "/profile/bookmark/getByType";
    await axios.post(uri, {
      asset_type: "story"
    })
      .then(res => setStoryIds(res.data))
  }
  /** fetch all Tags */
  const fetchTags = async () => {
    const uri = "/profile/bookmark/getByType";
    await axios.post(uri, {
      asset_type: "tag"
    })
      .then(res => setTagnames(res.data))
  }
  useEffect(() => {
    let isMounted = true;
    if (typeof window === 'undefined') {
      return;
    } else {
      const decodeURI = decodeURIComponent(window.location.href);
      var profileSlug = decodeURI.split('/').slice(3).join('/');
      if (profileSlug.includes('/')) profileSlug = profileSlug.substring(profileSlug.indexOf('/') + 1);
      setProfilePageSlug(profileSlug);
      if (isMounted) {
        const listed = JSON.parse(window.localStorage.getItem("ipUser"));
        setWlisted(listed);
        if (!listed) {
          fetchStoryIds();
          fetchTags();
        }
      }
    };
    setTimeout(() => {
      setIsDisplayed(true);
    }, 1000);
    return () => {
      isMounted = false;
    };
  }, [])
  const transformedTags = tagNames.map(tag => ({ name: tag }));

  /** based on the current url slug, decide what will be shown to user. Account links, Saved stories or Followed tags. */
  const getPage = () => {
    switch (profilePageSlug) {
      case "omat-tietoni":
        return (<ProfileInfo />)
      case "tallentamani-artikkelit":
        return (storyIds &&
          <MemoizedSavedStories
            storyIds={storyIds}
          />)
      case "seuraamani-aiheet":
        return (
          <>
            {
              <>
                <h1 styleName="top-headline">
                  Seuraamani aiheet<PopoverElement anchor="ri-information-line" />
                </h1>
                <p styleName="saved-stories-text">
                  Tällä sivulla näet seuraamasi aiheet ja pääset lukemaan niihin liittyviä artikkeleita. Näet jokaisesta aiheesta enintään 30 uusinta juttua.<br /><br />
                  Seurattavaksi valitsemasi aiheet tallennetaan ja niistä saattaa muodostua sinua profiloivia tietoja. Muistathan siis huolehtia tilisi ja salasanasi turvaamisesta!
                </p>
                {transformedTags.length > 0 &&
                  (tagsOpen ?
                    <a
                      styleName="tags-mobile-parent"
                      onClick={() => setTagsOpen(!tagsOpen)}
                    >
                      Piilota seuraamani aiheet <i className="ri-arrow-up-s-line"></i>
                    </a>
                    :
                    <a
                      styleName="tags-mobile-parent"
                      onClick={() => setTagsOpen(!tagsOpen)}
                    >
                      Näytä seuraamani aiheet <i className="ri-arrow-down-s-line"></i>
                    </a>)
                }
                {tagsOpen && <div styleName="tags-mobile">
                  <Tags tags={transformedTags} hideTags={hideTags} deleteAllTags={deleteAllTags} />
                </div>}
              </>
            }
            <h1 styleName="own-list-headline">
              Oma lista
            </h1>

            {(!hideTags && tagNames.length > 0) ? <MemoizedSavedTaggedStories
              tagNames={tagNames}
              config={config}
              hideTags={hideTags}
            />
              : <p styleName="tags-not-followed">Et ole seurannut yhtään aihetta</p>}
          </>)
      default:
        break;
    }
  }

  const getTitle = () => {
    switch (profilePageSlug) {
      case "omat-tietoni":
        return ("Omat tietoni")
      case "tallentamani-artikkelit":
        return ("Tallentamani artikkelit")
      case "seuraamani-aiheet":
        return ("Seuraamani aiheet")
      default:
        break;
    }
  }


  return (
    <React.Fragment>
      <div styleName="top-container">
        <div styleName="main-container">
          <div styleName="main">
          </div>
          <div styleName="profile-container">
            {checkUser ? (!wListed && <div styleName="profile-left-container">
              {getPage()}
            </div>) : (!wListed && <div styleName="profile-login-container" className="profile-login-container">
              {isDisplayed && <>
                <h1 styleName="top-headline">{getTitle()}</h1>
                <span styleName="text-profile">Kirjaudu sisään hallitaksesi tilaustasi, tallentaaksesi artikkeleita ja lukeaksesi artikkeleita seuraamistasi aiheista.</span></>}
              {isDisplayed ? <LoginFormBase /> : <div styleName="custom-loader"></div>}
            </div>)}
            {wListed && <div styleName="profile-login-container"><span styleName="text-profile">Kirjautuminen, artikkeleiden tallentaminen ja aiheiden seuraaminen poissa käytöstä. Artikkelit vapaasti luettavissa.</span></div>}
            <div styleName="popular-main">
              <div styleName="sidebar">
                {profilePageSlug == "seuraamani-aiheet" && <div styleName="tags-desktop">
                  <Tags tags={transformedTags} hideTags={hideTags} deleteAllTags={deleteAllTags} />
                </div>}
                <Newsletter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
})
ProfilePage.propTypes = {
  config: object
};
export { ProfilePage };
