import React from "react";
import TimeStamp from "../atoms/time-stamp";

export const getTimeStampLayout = (domainSlug, paperValues, storyObj) => {
  if (
    domainSlug === "ilkka-pohjalainen" &&
    (paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ")
  ) {
    return (
      <div className="time-stamp-layout1">
        <TimeStamp timeStamp={storyObj["published-at"]} prefix={paperValues.paperName} />
      </div>
    );
  } else {
    return (
      <div className="time-stamp-layout1">
        <TimeStamp timeStamp={storyObj["published-at"]} />
      </div>
    );
  }
};
