import React, { useEffect, useState } from "react";
import SavedStoriesLayout from "../saved-stories-layout";

import "./saved-articles.m.css";

const SavedStories = ({ storyIds }) => {
  const [stories, setStories] = useState([]);
  useEffect(() => {
    async function fetchAPI() {
      const responses = await Promise.all(
        storyIds.map(
          async id =>
            await fetch(`/api/v1/stories/${id}`)
              .then(value => value.json())
              .then(json => json.story)
        )
      );
      setStories(responses);
    }
    fetchAPI();
  }, [storyIds]);
  return (
    <div styleName="saved-stories-container">
      {
        <SavedStoriesLayout story={stories} />
      }
    </div>
  );
};

export default SavedStories;
