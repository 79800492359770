import React, { useEffect, useState } from "react";
import get from "lodash/get";
import useUrl from "../../../hook/useUrl";
import { Link } from "@quintype/components";
import TimeStamp from "../../atoms/time-stamp";
import CardImage from "../../atoms/card-image";
import { BookmarkIcon } from "../bookmark-icon";
import StarShowHide from "../../atoms/star-show-hide";
import { CommentIcon } from "../comment-icon";
import { object, array, func, bool } from "prop-types";
import { generateHref } from "../../utils/generate-href";
import { generateProfileHref } from "../../utils/generate-profile-href";
import { SectionCategoryTitle } from "../section-category-title";
import { PopoverElement } from "../../atoms/popover-element";
import "./card-image-type-8.m.css";
import { generatePaperValues } from "../../utils/generate-paper-values";

const CardImageType8 = ({
  onClickDelete,
  story,
  showSection = false,
  removeStory = false,
  widths = [250, 375],
  storytags,
  page = ""
}) => {
  const [_, currentUrlSlug] = useUrl();
  const storyObj = story.story ? story.story : story;
  const paaaihe = storyObj.metadata ? storyObj.metadata["pääaihe"] : undefined;
  const liveIndicator = get(story, ["metadata", "story-attributes", "paivittyvajuttu", 0]);
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [isCollectionPage, setIsCollectionPage] = useState(false);
  const [isUserContentPage, setIsUserContentPage] = useState(false);
  const [showBookmark, setShowBookmark] = useState(true);
  var paperValues = generatePaperValues(storyObj);
  var strippedTextBlock;
  if (storyObj && storyObj.cards) {
    if (storyObj.cards[0]["story-elements"]) {
      var firstTextBlock = storyObj.cards[0]["story-elements"][0].text || undefined;
      strippedTextBlock = firstTextBlock && firstTextBlock.replace(/(<([^>]+)>)/gi, "");
      if (firstTextBlock === "" || firstTextBlock === undefined || firstTextBlock === null) strippedTextBlock === "";
      else strippedTextBlock = firstTextBlock.replace(/(<([^>]+)>)/gi, "");
    }
  } else strippedTextBlock = "";
  const imgS3keys = storyObj["hero-image-s3-key"];

  useEffect(() => {
    if (typeof window === "undefined") {
      console.log("Oops, `window` is not defined")

    } else {
      const decodeURI = decodeURIComponent(window.location.href);
      if (decodeURI.includes("tallentamani-artikkelit")
        || decodeURI.includes("seuraamani-aiheet")
        || decodeURI.includes("/topic/")
        || decodeURI==='http://localhost:3000/'
        || decodeURI==='https://yhteissivusto.fiare.qtstage.io/'
        || decodeURI==='https://imediat-web.fiare.qtstage.io/'
        || decodeURI==='https://ilkkapohjalainen.fi/') {
        setIsProfilePage(true);
      }
    }
    const decodeURI = decodeURIComponent(window.location.href);
    if (decodeURI.includes("/collection/")) {
      setIsCollectionPage(true);
    }
    if (decodeURI.includes("tallentamani-artikkelit")
      || decodeURI.includes("seuraamani-aiheet")
      || decodeURI.includes("/topic/")
      || decodeURI.includes("/collection/")) {
        setIsUserContentPage(true);
    }
    if (decodeURI.includes("tallentamani-artikkelit")) {
      setShowBookmark(false);
    }

  }, [])

  let accessType = storyObj.access;
  if (!isProfilePage) {
    switch (currentUrlSlug) {
      case "vaasalehti":
        accessType = "public";
        break;
      case "epari":
        accessType = "public";
        break;
      default:
        accessType = accessType;
    }
  }

  else if (isProfilePage) {
    accessType = paperValues.accessType;
  }

  const getIcon = type => {
    if (type === "photo") {
      return (
        <div styleName="icon">
          <img src="/assetify/photo.svg" alt="photo" />
        </div>
      );
    } else if (type === "video") {
      return (
        <div styleName="icon">
          <i className="ri-movie-line"></i>
        </div>
      );
    }
  };

  return (
    <div styleName="wrapper" className="card-image-type-8">
      <Link href={isProfilePage ? generateProfileHref(storyObj) : generateHref(storyObj, currentUrlSlug)}>
        <div styleName="card-image-type-8" className="card-image-type-8-card">
          {showSection && <SectionCategoryTitle name={storyObj.sections[0].name} />}
          {imgS3keys !== null && (
            <div>
              <div styleName="image-desktop">
                <CardImage
                  story={storyObj}
                  defaultWidth={183}
                  aspectRatio={[3, 2]}
                  imageType="image3x2"
                  widths={widths}
                />
              </div>
              <div styleName="image-mobile">
                <CardImage
                  story={storyObj}
                  defaultWidth={89}
                  aspectRatio={[1, 1]}
                  imageType="image1x1"
                  widths={widths}
                />
              </div>
              <div styleName="info" className="info">
                {getIcon(storyObj["story-template"])}
              </div>
            </div>
          )}
        </div>
      </Link>
      <div className="main-headline" styleName={imgS3keys ? "story-content" : "story-content-no-img"}>
        <div styleName="wrapper-inside" className="wrapper-inside">
          <Link href={isProfilePage ? generateProfileHref(storyObj) : generateHref(storyObj, currentUrlSlug)}>
            {(liveIndicator) ? (
              <div styleName="live-indicator" className="live-indicator"><span styleName="blink"></span>{liveIndicator}</div>
            ) : (
              null
            )}
            <h2 styleName="headline" className="headline-layout4 main-headline-layout1 layout4">
              <span styleName="span-newest" className="span-newest-card-8">
                {paaaihe && `${paaaihe} | `}
              </span>
              {storyObj.headline}
            </h2>
          </Link>
          {isUserContentPage && paperValues.paperName !== "Epari | " ? (
            <div styleName="section-time-wrapper-user-content-page">
            <div className="time-stamp-layout1">
              <TimeStamp timeStamp={storyObj["published-at"]} prefix={
              isProfilePage && (paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | " || page === "tag" || page === "profile")
              ? paperValues.paperName
              : isCollectionPage
              ? paperValues.paperName
              : "" }
              />
            </div>
            {(paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ") ? null : <StarShowHide accessType={accessType} />}
            <CommentIcon storyObj={storyObj} />
            <BookmarkIcon user_flag="bm_user_flag" storyObj={storyObj} showBookmark={showBookmark} />
            {removeStory && (
              <button
                styleName="bookmark-btn"
                onClick={onClickDelete}
                aria-label="Poista tallennus"
                title="Poista tallennus">

                <PopoverElement anchor="ri-bookmark-fill" />
              </button>
            )}
            </div>
          ) : (
            <div styleName="section-time-wrapper">
            <div className="time-stamp-layout1">
              <TimeStamp timeStamp={storyObj["published-at"]} prefix={
              isProfilePage && (paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | " || page === "tag" || page === "profile")
              ? paperValues.paperName
              : isCollectionPage
              ? paperValues.paperName
              : "" }
              />
            </div>
            {(paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ") ? null : <StarShowHide accessType={accessType} />}
            <CommentIcon storyObj={storyObj} />
            <BookmarkIcon user_flag="bm_user_flag" storyObj={storyObj} showBookmark={showBookmark} />
            {removeStory && (
              <button
                styleName="bookmark-btn"
                onClick={onClickDelete}
                aria-label="Poista tallennus"
                title="Poista tallennus">

                <PopoverElement anchor="ri-bookmark-fill" />
              </button>
            )}
          </div> )}
          <div styleName="storytags" className="storytags">
            {storytags && storytags.join(', ')}
          </div>
        </div>
      </div>
    </div>
  );
};
CardImageType8.propTypes = {
  story: object,
  widths: array,
  onClickDelete: func,
  showSection: bool,
  showBookmark: bool,
  removeStory: bool
};
export default React.memo(CardImageType8);
