import React from "react";
import { Link } from "@quintype/components";

import CardImage from "../../atoms/card-image";
import { SectionTag } from "@quintype/arrow";

import "./card-image-type-3.m.css";

const CardImageType3 = ({story, showSection=false}) => {
  const storyObj = story.story ? story.story : story;

  return (
    <Link styleName="wrapper" href={`/${storyObj.slug}`}>
        <CardImage
          story={storyObj}
          defaultWidth={320}
          aspectRatio={[16, 9]}
          imageType="image16x9"
          widths={[320, 200]}
        />
        <div className="story-content" styleName="story-content">
          <h3 styleName="main-headline" className="main-headline">{showSection && <SectionTag story={storyObj} />}{storyObj.headline}</h3>
      </div>
    </Link>
  );
}

export default CardImageType3 ;
