import React from "react";
import {SearchBox} from "../../molecules/search-box"
import "./not-found.m.css";

class NotFoundPage extends React.Component {
  state = { collection: null };

  componentDidMount() {
    this.getLatestnews();
  }

  getLatestnews = async () => {
    const response = await window.fetch("/api/v1/collections/ulkomaat?item-type=story&limit=10&offset=0");
    const jsonResponse = await response.json();
    this.setState({ collection: jsonResponse });
  };

  render () {
      const getCollection = {
       collection: this.state.collection
      }
    return (
      <div styleName="wrapper">
        <div styleName="content">
          <div styleName="notfound-text">
            <h1 styleName="status">Sivua ei löytynyt!</h1>
            <p styleName="status-message">
              Virhe 404. Sivua ei ole olemassa tai osoite on virheellinen.
            </p>
          </div>
          <div styleName="searchbox">
            <SearchBox />
          </div>
          <div styleName="returnbtn">
            <a href="/" aria-label="Etusivulle" styleName="button">
              <i className="ri-arrow-left-line"></i>Palaa etusivulle
            </a>
          </div>
        </div>
    </div>
    );
  }
}

export {NotFoundPage};
