import React, { useEffect, useState } from "react";
import LazyEagerImage from "./atoms/lazy-eager";
import { Link } from "@quintype/components";
import "./pointti.m.css";

function Pointti() {
  const [data, setData] = useState([]);
  useEffect(()=>{
    async function fetchAPI() {
      await fetch("https://integration.imediat.fiareads.com/read_pointti_widget_data.php")
      .then((res) => res.json())
      .then((json) => {
        //console.log("json",json.data);
        setData(json.data)})
    }
    fetchAPI();
  },[])

  return (
    <React.Fragment>
      <div styleName="pointti-container">
        <div styleName="header">
        <div styleName="logo">
          <Link href="https://pointti.fi?utm_source=IPP&amp;utm_campaign=IPP_pointti">
            <LazyEagerImage src="/assetify/pointti_logo.png" alt="pointti.fi" />
          </Link>
        </div>
        <div styleName="title">
          <h2>Päivän parhaat pointit</h2>
        </div>
        <div styleName="mainos">
          <div>Mainos</div>
        </div>
      </div>
      <div styleName="nostoboksi">
          {data.slice(0,3).map((item, index) => {
            if (item.content_type === "article") {
              return (
                <React.Fragment key={index}>
                  <div styleName="nosto" key={index}>
                    <LazyEagerImage src={item.article_image_thumb} alt={item.category} />
                    <div styleName="nostoteksti">
                    <a href={`${item.campaign_url ? item.campaign_url : item.link }`}>
                      <h3>{item.post_title}</h3>
                      <p>{item.post_excerpt}</p>
                      <p>Lue lisää &gt;&gt;</p>
                    </a>
                    </div>
                  </div>
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={index}>
                <div styleName="liitenosto" key={index}>
                  <img src={item.article_image_thumb}/>
                  <a href={`${item.campaign_url ? item.campaign_url : item.link }`}>
                    <h3>Lue tuorein <br/>{item.post_title}<br/>-liite!</h3>
                  </a>
                </div>
              </React.Fragment>
            );
            })}
            </div>
            <div styleName="footer">
              <a href="https://pointti.fi?utm_source=IPP&amp;utm_campaign=IPP_lue_lisaa">
                <h4>Lue lisää pointteja &gt;&gt;</h4>
              </a>
          </div>
      </div>
    </React.Fragment>
  )
}

export default Pointti;

