import PT from "prop-types";
import React, { useEffect, useState } from "react";
import SuosituimmatChild from "./suosituimmat-child";
import { ShowMoreBtn } from "../../atoms/show-more-btn";

import "./suosituimmat.m.css";

const Suosituimmat = ({ collectionslug }) => {
  const slugValue =
    collectionslug !== "" && collectionslug !== undefined
      ? "luetuimmat-" + collectionslug
      : "luetuimmat-ilkka-pohjalainen";
  const [loadMore, setLoadMore] = useState(5);
  const [suosituimmat, setSuosituimmat] = useState([]);
  const [collectionExists, setcollectionExists] = useState(true);
  var [showBtn, setShowBtn] = useState(true);
  useEffect(() => {
    async function fetchAPI() {
      const stories = await fetch(`/api/v1/collections/${slugValue}`);
      const response = await stories.json();
      var resultData = response.items;
      if (resultData == undefined) {
        setcollectionExists(false);
        const defaultStories = await fetch(`/api/v1/collections/luetuimmat-ilkka-pohjalainen`);
        const defaultResponse = await defaultStories.json();
        resultData = defaultResponse.items;
      }
      setSuosituimmat(resultData);
    }
    fetchAPI();
  }, []);
  const suosituimmata = [...(suosituimmat || [])];
  if (suosituimmata.length < 1) {
    return null;
  }
  const handleLoadMore = () => {
    setLoadMore(loadMore + 5);
    if (suosituimmata.length <= loadMore + 5) setShowBtn(false);
  };
  return (
    <div styleName="sidebar-global">
      {collectionslug !== "" && collectionslug !== undefined && collectionExists === true ? (
        <h2 className="top-headline" styleName="top-headline">
          OSION LUETUIMMAT
        </h2>
      ) : (
        <h2 className="top-headline" styleName="top-headline">
          LUETUIMMAT
        </h2>
      )}
      {suosituimmata.slice(0, loadMore).map((item, index) => (
        <div key={index}>
          <SuosituimmatChild item={item} />
        </div>
      ))}
      {showBtn === true && suosituimmata.length > 5 && <ShowMoreBtn loadMore={handleLoadMore} btnName="NÄYTÄ LISÄÄ" />}
    </div>
  );
};

Suosituimmat.propTypes = {
  collectionslug: PT.string
};

export default Suosituimmat;
