/* eslint-disable import/extensions */
import React, { useEffect } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { bool, number, object, shape } from "prop-types";
import SideBar from "../../molecules/sidebar";
import {LazyCollection,LazyLoadImages} from "@quintype/components";
import { EtusivuSuurtaulu } from "../../atoms/suurtaulu-frontpage";
import { getCollectionTemplate } from "../../get-collection-template";
import "./epari-home-page.m.css";

const EpariHome = ({ data, reloadTimeInMinutes = 15, shouldReload = false }) => {
  useEffect(() => {
    if (!shouldReload) return;
    let timeElapsed = 0;
    let timerId;

    const Main = () => {
      if (document.hidden && timeElapsed === 0) {
        startTimer();
      }
      if (!document.hidden && timeElapsed > 0 && timeElapsed < reloadTimeInMinutes) {
        clearInterval(timerId);
        timeElapsed = 0;
      }
      if (!document.hidden && timeElapsed >= reloadTimeInMinutes) {
        window.location.reload();
      }
    };

    function updateTimeElapsed() {
      timeElapsed = timeElapsed + 1;
    }
    function startTimer() {
      timerId = window.setInterval(updateTimeElapsed, 60000);
    }
    document.addEventListener("visibilitychange", Main);
    return () => {
      document.removeEventListener("visibilitychange", Main);
    };
  }, []);
  return (
    <div styleName="top-container">
    <div styleName="container">
      <div styleName="main">
        <div styleName="ad-container">
          {/* PARAATI mobiili*/}
          <div styleName="mob-ad" data-placement-id="paraatipaikka-mobile">
            <div id="sas_63333"></div>
          </div>
          {/* PARAATI deskari ja tabletti*/}
          <div styleName="dsktop-ad" data-placement-id="paraatipaikka-desktop">
            <div id="sas_63219"></div>
          </div>
        </div>
        <div styleName="ad-container">
          {/* PARAATI 2 mobiili*/}
          <div styleName="mob-ad">
            <div id="sas_112313" data-placement-id="paraatipaikka-2-mobile"></div>
          </div>
          {/* PARAATI 2 deskari ja tabletti*/}
          <div styleName="dsktop-ad">
            <div id="sas_112312" data-placement-id="paraatipaikka-2-desktop"></div>
          </div>
        </div>
        <EtusivuSuurtaulu />
      </div>
      <div styleName="lazy-pop">
        <div styleName="lazy-load">
         {/*<div styleName="right-now">
            <RightNow rightNow={rightNow} />
          </div>*/}
          <div styleName="popular-grid-hide">
          {/*<div styleName="sidebar-ad-hide" id="sas_76185"></div>*/}
            <div>
            {/*<SideBar mostPopular={mostPopular} />*/}
            </div>
          </div>
          <LazyLoadImages>
            <LazyCollection collection={data.collection} collectionTemplates={getCollectionTemplate} lazyAfter={22} />
          </LazyLoadImages>
        </div>
        <SideBar/>
      </div>
    </div>
    </div>
  );
};

EpariHome.propTypes = {
  data: shape({
    collection: object
  }),
  reloadTimeInMinutes: number,
  shouldReload: bool
};

function mapStateToProps(state) {
  return {
    shouldReload: get(state, ["qt", "config", "publisher-attributes", "shouldReload"], false),
    reloadTimeInMinutes: Number(get(state, ["qt", "config", "publisher-attributes", "reloadTimeInMinutes"], 15))
  };
}

export const EpariHomePage = connect(mapStateToProps, () => ({}))(EpariHome);

