import React, { useEffect, useState } from "react";
import "./games.m.css";

function Games() {


  return (
    <React.Fragment>
      <div styleName="games-container">
        <div styleName="text-container">
          <p>Joka päivä uusia ristikoita ja sanapelejä!</p>
          <div styleName="games-cta">
            <button styleName="to-games-btn" onClick={() => window.location.href="/pelit"}>
              Peleihin
            </button>
          </div>
        </div>
        <div styleName="icon-container">
          <div styleName="sana-icon">
              <img src="../assetify/Sana-icon.png"
              alt="Sana! Logo"
              data-retina_logo_url=""
              className="sana icon" />
          </div>
          <div styleName="sanaristikko-icon">
              <img src="../assetify/Sanaristikko-icon.png"
              alt="Sanaristikko Logo"
              data-retina_logo_url=""
              className="sanaristikko icon" />
          </div>
          <div styleName="hoyryristikko-icon">
              <img src="../assetify/Hoyryristikko-icon.png"
              alt="Hoyryristikko Logo"
              data-retina_logo_url=""
              className="hoyryristikko icon" />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Games;

