import React from "react";
import get from "lodash/get";
import { object } from "prop-types";

import { collectionToStories } from "@quintype/components";

import CardImageType7 from "../../molecules/card-image-type-7";

import "./one-big-img-news-cols.m.css";
import { first } from "lodash";

const OneBigImgNewsCols = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const pointerAdConfig = get(collection, ["associated-metadata", "pointer_ad"], true);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  //console.log("collectionii", collection);

  const storiesFromCollection = collectionToStories(collection);
  //console.log("storisFromCollectioniiii", storiesFromCollection[0].cards[0]["story-elements"][0].text);
  if (storiesFromCollection.length < 1) {
    return null;
  }
  const [firstStory, ...restOftheStories] = storiesFromCollection || [];

const heroImageS3 = firstStory["hero-image-s3-key"]

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
            {<div styleName="first-story first-story1">
              <CardImageType7 story={firstStory} showSection ={showSection} showSubHeadline ={false}/>
            </div>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

OneBigImgNewsCols.propTypes = {
  collection: object
};
export { OneBigImgNewsCols };

OneBigImgNewsCols.storyLimit = 22;
