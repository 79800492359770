import React from "react";
import { string } from "prop-types";
import "./section-category-title.m.css";
import LazyEagerImage from "../../atoms/lazy-eager";

const SectionCategoryTitle = ({ name }) => {

  return (
    <div styleName="title-line" className="">
      <div
        styleName={`hr-style ${name.includes("Epari") ? "epari" : ""} ${name.includes("Vaasa-lehti") ? "vaasa" : ""}`}
        className="horizontal-line"
      />
      <div styleName="category-title" className="category">
        {name.includes("Epari") ? (
          <LazyEagerImage src="/assetify/epari_logo.png" alt="Epari" styleName="epari-logo"/>
        ) : name.includes("Vaasa-lehti") ? (
          <LazyEagerImage src="/assetify/vaasa_logo.png" alt="Vaasa" styleName="vaasa-logo"/>
        ) : name.includes("Kiekko-Ippe") ? (
          name.replace("(Kiekko-Ippe, Ilkka-Pohjalainen)", "")
        ) : name.includes("Pesis-Ippe") ? (
          name.replace("(Pesis-Ippe, Ilkka-Pohjalainen)", "")
        ) : name.includes("Salibandy-Ippe") ? (
          name.replace("(Salibandy-Ippe, Ilkka-Pohjalainen)", "")
        ) : (
          name
        )}
      </div>
    </div>
  );
};

SectionCategoryTitle.propTypes = {
  name: string
};
export { SectionCategoryTitle };
