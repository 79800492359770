import React from "react";
import { func, array, bool } from "prop-types";
import { Link } from "@quintype/components";
import TimeStamp from "../../atoms/time-stamp";
import StarShowHide from "../../atoms/star-show-hide";
import CardImageType8 from "../card-image-type-8";
import PT from 'prop-types';
import "./section-show-more-stories.m.css";
import { BookmarkIcon } from "../bookmark-icon";
import { CommentIcon } from "../comment-icon";
import { generatePaperValues } from "../../utils/generate-paper-values";
import { generateHref } from "../../utils/generate-href";

const SectionShowMoreStories = ({ stories, startIndex, itemsToLoad, followedtags, page = "" }) => {
  return stories.slice(startIndex, itemsToLoad).map((story, index) => {
    const storyObj = story.story || story;
    const storyTagNames = story.tags && (story.tags).map(tag => tag.name);
    const followedtagsLower = followedtags && followedtags.map(tag => tag.toLowerCase());
    const storytags = (storyTagNames && followedtags) && storyTagNames.filter(tagname => followedtagsLower.includes(tagname.toLowerCase()));

    var paperValues = generatePaperValues(storyObj);
    /* getting url right regarding different papers*/

    function findByKey(key, value) {
      return (item, i) => item[key] === value
    }

    let findParams = findByKey("domain-slug", "ilkka-pohjalainen") //change here
    let positionOfPaper = storyObj.sections.findIndex(findParams)

    var parts = storyObj.slug.lastIndexOf("/");
    var storySlug = storyObj.slug.substring(parts + 1);

    // Check if there is section for that paper
    if (storyObj.sections[positionOfPaper]) {
      var splitUrl = storyObj.sections[positionOfPaper]["section-url"].split("/"); //split when slash
      var joinUrl = splitUrl.slice(3); //slice after third slug
      var finalUrl = joinUrl.join("/"); //join the remaining parts with slash
    }

    return (

      <React.Fragment key={index}>

        {story["hero-image-s3-key"] !== null ?
          <div className="item" styleName="item" key={story.id}>
            <CardImageType8 story={story} showSubHeadline={false} storytags={storytags} page={page} />
          </div> :

          <div className="main-headline item" styleName={story["hero-image-s3-key"] ? "story-content" : "story-content-no-img"}>
            <Link href={
              page === "tag" ? generateHref(storyObj, paperValues.storysCurrentUrlSlug) : positionOfPaper !== -1 ? (
                storyObj.sections[positionOfPaper] ? (
                  `/${finalUrl}/${storySlug}`
                ) : (
                  `/${storyObj.sections[positionOfPaper].slug}/${storySlug}`
                )
              ) : (
                `/${storyObj.slug}`
              )
            }>
              <div styleName="wrapper-inside" className="wrapper-inside">

                <h2 styleName="" className="headline-layout4 main-headline-layout1 layout4">
                  <span styleName="span-newest">{story.metadata && story.metadata["pääaihe"] && `${story.metadata["pääaihe"]} | `}</span>
                  {story.headline}
                </h2>
                <div styleName="section-time-wrapper">
                  <div className="time-stamp-layout1">
                    <TimeStamp timeStamp={story["published-at"]} prefix={(paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | " || page === "tag")
                    ? paperValues.paperName : ''} />
                  </div>
                  {(paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ") ? null : <StarShowHide accessType={page == "tag" ? paperValues.accessType : story.access} />}
                  <CommentIcon storyObj={storyObj} />
                  <BookmarkIcon user_flag="bm_user_flag" storyObj={storyObj} showBookmark={true} />
                </div>
                <div styleName="storytags" className="storytags">
                  {storytags && storytags.join(', ')}
                </div>
              </div>
            </Link>
          </div>

        }
      </React.Fragment>
    );
  });
};

export default SectionShowMoreStories;

SectionShowMoreStories.propTypes = {
  onLoadMore: func,
  stories: array,
  noMoreStories: bool,
  itemsToLoad: PT.number
};
