import React from "react";
import { wrapCollectionLayout, EagerLoadImages } from "@quintype/components";
import { FourColGridRow } from "../rows/four-col-grid";
import { StoryCardSlider } from "../rows/story-card-slider";
import { FullScreenSliderRow } from "../rows/full-screen-slider-row";
import { ThreeColFifteenStoriesRow } from "../rows/three-col-fifteen-stories-row";
import { TwoColWithSevenStoriesRow } from "../rows/two-col-with-seven-stories-row";
import { FeatureSliderRow } from "../rows/feature-slider";
import { OpinionPolls } from "../rows/opinion-polls";
import { OneColFourStories } from "../rows/one-col-four-stories";
import { FourColFourRowsTenStories } from "../rows/four-col-four-rows-ten-stories";
import { LargeNewsImgCard } from "../rows/large-news-img-card";
import { TwoColFourStoriesRow } from "../rows/two-col-four-stories-row";
import { NoImgNewsCards } from "../rows/no-image-news-cards";
import { TwoRowsThreeCols } from "../rows/two-rows-three-cols";
import { NativeAdLayout } from "../rows/native-ad-layout";
import { SmallImgNewsCards } from "../rows/small-image-news-cards";
import { ThreeSmallNewsCardImg } from "../rows/three-small-news-cards-with-img";
import { OneColFourStoriesNoAd } from "../rows/one-col-4-stories-no-ad";
import { OneBigThreeSmallImgNewsRows } from "../rows/one-big-3-small-img-news-rows";
import { OneBigTwoSmallImgNewsCols } from "../rows/one-big-2-small-img-news-cols";
import { OneBigThreeSmallImgNewsCols } from "../rows/one-big-3-small-img-news-cols";
import { OneBigThreeSmallNewsRowsNoImg } from "../rows/one-big-3-small-news-rows-no-img";
import { SixSmallNewsImgNHeadline } from "../rows/six-small-news-img-headline";
import { OneBigThreeSmallImgNewsColsWithPointti } from "../rows/one-big-3-small-img-news-cols-with-pointtinosto";
import { LargeArticlePreview } from "../rows/large-articles-preview";
import { FourSmallImgNewsCols } from "../rows/four-small-image-news-cols";
import { FiveSmallImgNewsRows } from "../rows/five-small-img-news-rows";
import { BigImagesWithAds } from "../rows/big-images-with-ads";
import { BlackAndWhite } from "../rows/black-white";
import { OneBigThreeSmallImgNewsColsPeach } from "../rows/one-big-3-small-img-news-cols-peach";
import { LargeArticlePreviewPeach } from "../rows/large-articles-preview-peach";
import { OneBigThreeSmallImgNewsColsYellow } from "../rows/one-big-3-small-img-news-cols-yellow";
import { LargeArticlePreviewYellow } from "../rows/large-articles-preview-yellow";
import { OneBigThreeSmallImgNewsColsPurple } from "../rows/one-big-3-small-img-news-cols-purple";
import { LargeArticlePreviewPurple } from "../rows/large-articles-preview-purple";
import { Luetuimmat } from "../rows/luetuimmat";
import { RightNow } from "../rows/right-now";


import "./collection-templates.m.css";


function wrapEager(f) {
  const wrappedComponent = function WrapEager(props) {
    if (props.index === 0) {
      return (
        <>
        <EagerLoadImages predicate={token => token === "above-fold"}>{React.createElement(f, props)}</EagerLoadImages>
        </>
      );
    } else {
      return (
        <>
          {React.createElement(f, props)}
        </>
      )
    }
  };

  if (f.storyLimit) {
    wrappedComponent.storyLimit = f.storyLimit;
  }

  return wrappedComponent;
}

export default {

  TwoColWithSevenStoriesRow: wrapEager(wrapCollectionLayout(TwoColWithSevenStoriesRow)),
  TwoRowsThreeCols: wrapEager(wrapCollectionLayout(TwoRowsThreeCols)),
  TwoColFourStoriesRow: wrapEager(wrapCollectionLayout(TwoColFourStoriesRow)),
  ThreeColFifteenStoriesRow: wrapEager(wrapCollectionLayout(ThreeColFifteenStoriesRow)),
  OpinionPolls: wrapEager(wrapCollectionLayout(OpinionPolls)),
  FourColFourRowsTenStories: wrapEager(wrapCollectionLayout(FourColFourRowsTenStories)),
  FourColGridRow: wrapEager(wrapCollectionLayout(FourColGridRow)),
  FullScreenSliderRow: wrapEager(wrapCollectionLayout(FullScreenSliderRow)),
  FeatureSliderRow: wrapEager(wrapCollectionLayout(FeatureSliderRow)),
  StoryCardSlider: wrapEager(wrapCollectionLayout(StoryCardSlider)),
  LargeNewsImgCard: wrapEager(wrapCollectionLayout(LargeNewsImgCard)),
  ThreeSmallNewsCardImg: wrapEager(wrapCollectionLayout(ThreeSmallNewsCardImg)),
  OneColFourStories: wrapEager(wrapCollectionLayout(OneColFourStories)),
  OneColFourStoriesNoAd: wrapEager(wrapCollectionLayout(OneColFourStoriesNoAd)),
  SmallImgNewsCards: wrapEager(wrapCollectionLayout(SmallImgNewsCards)),
  NoImgNewsCards: wrapEager(wrapCollectionLayout(NoImgNewsCards)),
  NativeAdLayout: wrapEager(wrapCollectionLayout(NativeAdLayout)),
  OneBigThreeSmallImgNewsRows: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsRows)),
  OneBigTwoSmallImgNewsCols: wrapEager(wrapCollectionLayout(OneBigTwoSmallImgNewsCols)),
  OneBigThreeSmallImgNewsCols: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsCols)),
  OneBigThreeSmallImgNewsColsPeach: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsColsPeach)),
  OneBigThreeSmallImgNewsColsYellow: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsColsYellow)),
  OneBigThreeSmallImgNewsColsPurple: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsColsPurple)),
  OneBigThreeSmallNewsRowsNoImg: wrapEager(wrapCollectionLayout(OneBigThreeSmallNewsRowsNoImg)),
  SixSmallNewsImgNHeadline: wrapEager(wrapCollectionLayout(SixSmallNewsImgNHeadline)),
  LargeArticlePreview: wrapEager(wrapCollectionLayout(LargeArticlePreview)),
  FourSmallImgNewsCols: wrapEager(wrapCollectionLayout(FourSmallImgNewsCols)),
  FiveSmallImgNewsRows: wrapEager(wrapCollectionLayout(FiveSmallImgNewsRows)),
  RightNow: wrapEager(wrapCollectionLayout(RightNow)),
  OneBigThreeSmallImgNewsColsWithPointti: wrapEager(wrapCollectionLayout(OneBigThreeSmallImgNewsColsWithPointti)),
  Luetuimmat: wrapEager(wrapCollectionLayout(Luetuimmat)),
  BigImagesWithAds: wrapEager(wrapCollectionLayout(BigImagesWithAds)),
  BlackAndWhite: wrapEager(wrapCollectionLayout(BlackAndWhite)),
  defaultTemplate: wrapEager(wrapCollectionLayout(LargeArticlePreview)),
  LargeArticlePreviewPeach: wrapEager(wrapCollectionLayout(LargeArticlePreviewPeach)),
  LargeArticlePreviewYellow: wrapEager(wrapCollectionLayout(LargeArticlePreviewYellow)),
  LargeArticlePreviewPurple: wrapEager(wrapCollectionLayout(LargeArticlePreviewPurple))
};
