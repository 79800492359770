/* eslint-disable import/extensions */
import React, { useState } from "react";
import { get } from "lodash";
import PT from "prop-types";
import SectionShowMoreStories from "../../molecules/section-show-more-stories"
import { getCollectionTemplate } from "../../get-collection-template";
import { OneBigImgNewsCols } from "../../rows/one-big-img-news-cols";
import CardImageType8 from "../../molecules/card-image-type-8";
import { postBookmark } from "../../utils/post-bookmark";
import { ShowMoreBtn } from "../../atoms/show-more-btn";
import { Collection } from "@quintype/components";
import { Suurtaulu } from "../../atoms/suurtaulu";
import { IppeTop } from "../../atoms/ippe-top";
import SideBar from "../../molecules/sidebar";
import useUrl from "../../../hook/useUrl";
import "./section.m.css";
import { generatePaperValues } from "../../utils/generate-paper-values";
import LazyEagerImage from "../../atoms/lazy-eager";

const storiesDom = (stories, startIndex, collectionslug) => {
  const [_,currentUrlSlug]=useUrl();
  return stories.slice(startIndex, 30).map((story, index) => {
    const storyObj = story.story || story;
  var paperValues = generatePaperValues(storyObj);
  let domainSlug = "";
  switch (currentUrlSlug) {
    case "vaasalehti":
      domainSlug = "vaasa";
      break;
    case "epari":
      domainSlug = "epari";
      break;
    case "":
      domainSlug="ilkka-pohjalainen"
      break;
    default:
      domainSlug = "ilkka-pohjalainen";
  }
    return (
      <React.Fragment key={index}>
        <div styleName="desktop-ad-slots">
          {index === 0 && (
            <div styleName="ad-container-between-articles">
              <div styleName="sidebar-ad-mobile" data-lazy-ad-unit-id="sas_63337" data-placement-id="2.mainospaikka-mobile"></div>
              <div styleName="sidebar-ad-mobile" data-lazy-ad-unit-id="sas_120817" data-placement-id="2a.mainospaikka-mobile"></div>
              {/*<div styleName="newest-category-mobile">
              <Suosituimmat collectionslug={collectionslug} />
              </div>*/}
              <div data-lazy-ad-unit-id="sas_106329" styleName="display-in-screen indexes" data-placement-id="mainospaikka-1-uutisvirta-desktop"></div>
              <div data-lazy-ad-unit-id="sas_106330" styleName="mobile-ad indexes" data-placement-id="mainospaikka-1-uutisvirta-mobile"></div>
            </div>
          )}
          {index === 4 && (
            <div styleName="ad-container-between-articles">
              <div data-lazy-ad-unit-id="sas_106331" styleName="display-in-screen indexes" data-placement-id="mainospaikka-2-uutisvirta-desktop"></div>
              <div data-lazy-ad-unit-id="sas_106332" styleName="mobile-ad indexes" data-placement-id="mainospaikka-2-uutisvirta-mobile"></div>
            </div>
          )}
          {index === 8 && (
            <div styleName="ad-container-between-articles">
              <div data-lazy-ad-unit-id="sas_106333" styleName="display-in-screen indexes" data-placement-id="mainospaikka-3-uutisvirta-desktop"></div>
              <div data-lazy-ad-unit-id="sas_106334" styleName="mobile-ad indexes" data-placement-id="mainospaikka-3-uutisvirta-mobile"></div>
            </div>
          )}
          {index === 12 && (
            <div styleName="ad-container-between-articles">
              <div data-lazy-ad-unit-id="sas_106335" styleName="display-in-screen indexes" data-placement-id="mainospaikka-4-uutisvirta-desktop"></div>
              <div data-lazy-ad-unit-id="sas_106336" styleName="mobile-ad indexes" data-placement-id="mainospaikka-4-uutisvirta-mobile"></div>
            </div>
          )}
        </div>
        {
          <div className="item" key={story.id}>
            <CardImageType8 story={story} showSubHeadline={false} />
          </div>
        }
      </React.Fragment>
    );
  });
};
const SectionPage = props => {
  const { data } = props;
  const storiesFromCollection = get(data, ["collection", "items"], []).filter(items => items.type === "story") || [];
  const collectionItems = get(data, ["collection", "items"], []).filter(items => items.type === "collection");
  const initialStories = storiesFromCollection.length > 1 ? storiesFromCollection.slice(1, 30) : [];
  const [extraStoriesData, updateExtraStoriesData] = useState(initialStories);
  const [pageNumber, updatePageNumber] = useState(Number(data.page) || 1);
  const [storyLimit, setStoryLimit]= useState(30);
  const onItemsToLoadClick = ()=> setStoryLimit(storyLimit + 10);

  const collectionStory = {
    items: storiesFromCollection,
    name: data.collection.name
  };
  const collectionOfCollection = {
    items: collectionItems
  };
  const [slug, checkSlug] = useState(data.collection.slug);
  var collectionslug;
  if (data.collection === undefined) {
    collectionslug = "";
  } else {
    collectionslug = data.collection.slug;
  }
  return (
    <div styleName={`top-container ${data.section&&data.section.collection.slug.includes("ippe") ? "ippe-collection" : ""}`}>
      <div styleName="container">
        <div styleName="main">
          <div styleName="ad-container">
            <div styleName="mob-ad">
              <div id="sas_63333" data-placement-id="paraatipaikka-mobile"></div>
            </div>
            <div styleName="dsktop-ad">
              <div id="sas_63219" data-placement-id="paraatipaikka-desktop"></div>
            </div>
          </div>
          <div styleName="ad-container">
            <div styleName="mob-ad">
              <div id="sas_112313" data-placement-id="paraatipaikka-2-mobile"></div>
            </div>
            <div styleName="dsktop-ad">
              <div id="sas_112312" data-placement-id="paraatipaikka-2-desktop"></div>
            </div>
          </div>
      </div>
      {(data.section && data.section.collection["slug"] === "epari-ilkka-pohjalainen") && //TÄHÄN HALUTTU SLUGI
            (<div styleName="section-top-epari">
              <div styleName="section-title">
                <LazyEagerImage src="/assetify/epari_logo.png" alt="Eparin logo"/>
              </div>
            </div>)}

      {(data.section && data.section.collection["slug"] === "vaasa-lehti-ilkka-pohjalainen") &&//TÄHÄN HALUTTU SLUGI
      (<div styleName="section-top-vaasa">
        <div styleName="section-title">
          <LazyEagerImage src="/assetify/vaasa_logo.png" alt="Vaasan logo"/>
        </div>
      </div>)}
      {//(data.section && data.section.collection.name.includes("ippe")) &&//TÄHÄN HALUTTU NIMI
      (data.section && data.section.collection && data.section.collection.slug && data.section.collection.slug.includes("ippe")) &&//TÄHÄN HALUTTU SLUGI
      (<IppeTop slug={data.collection.slug} />)
      }
      <div styleName="lazy-pop">
        <div styleName="section-content">

        {data.section && data.section.collection && data.section.collection.slug && data.section.collection.slug.includes("kiekko-ippe") &&
          <div styleName="ippe-title kiekko">
              Kiekko-Ippe
          </div>
        }
         {data.section && data.section.collection && data.section.collection.slug && data.section.collection.slug.includes("pesis-ippe") &&
          <div styleName="ippe-title pesis">
              Pesis-Ippe
          </div>}
        {data.section && data.section.collection && data.section.collection.slug && data.section.collection.slug.includes("salibandy-ippe") &&
          <div styleName="ippe-title salibandy">
              Salibandy-Ippe
        </div>}

        {(data.section !== undefined) ?
          ((data.section && data.section.collection["slug"] !== "vaasa-lehti-ilkka-pohjalainen") && (data.section && data.section.collection["slug"].includes !== "ippe") && ((data.section && data.section.collection["slug"] !== "epari-ilkka-pohjalainen") &&
            <div styleName="section-top">
              <div styleName="section-title">
                {data.section["display-name"]}
              </div>
              <div styleName="section-line"></div>
            </div>))
                :
              (<div styleName="section-top">
                <div styleName="section-title">
                  {data.collection.name.replace(/ *\([^)]*\) */g, "")}
                </div>
                <div styleName="section-line"></div>
              </div>
                )
                }
          <div styleName="layout-template">
            {storiesFromCollection.length > 0 ? (
              collectionStory && <OneBigImgNewsCols collection={collectionStory}/>
            ) : (
              <h2 styleName="no-story-headline">Ei artikkeleita...</h2>
            )}
              {collectionItems.length >= 1 && (
                <Collection collection={collectionOfCollection} collectionTemplates={getCollectionTemplate} />
              )}
            </div>
            <div styleName="bottom-stories-with-sidebar">
              {storiesDom(extraStoriesData, 0, collectionslug)}
              {<SectionShowMoreStories stories={storiesFromCollection} startIndex={30} itemsToLoad={storyLimit} />}
              {(storiesFromCollection.length>30 && (storiesFromCollection.length >= storyLimit+1)) ?
              <ShowMoreBtn loadMore={onItemsToLoadClick} btnName="NÄYTÄ LISÄÄ" />:null}
            </div>
          </div>
          <SideBar useCollection={slug} pageType={"section"}/>
        </div>
      </div>
      <Suurtaulu />
    </div>
  );
};

SectionPage.propTypes = {
  data: PT.object,
  onLoadMore: PT.func,
  stories: PT.array,
  noMoreStories: PT.bool
};

export { SectionPage };
