import React, { useState } from "react";
import { object, number, string, func, bool,array } from "prop-types";
import Pagination from "../../pages/pagination";
import PaginationStory from "../pagination-story";
import "./load-more-search-results.m.css";

const LoadMoreSearchResults = props => {
  const { stories } = props.data;
  const orderedStories = props.orderedStories;
  const initialData = props.initialData;
  const [isTrue, setIsTrue] = useState(false);
  const toggle = () => setIsTrue(!isTrue);
  return (
    <div>
      {orderedStories.length && initialData.length ? (
        <div>
          <div styleName="search-sorting">
            <span>Järjestä: </span>
            <span styleName={props.dates ? "by-date" : "by-default"} onClick={() => { props.sortByDate() }}>Päivämäärän mukaan</span>
            <span styleName={props.byRelativity ? "by-reletivity" : "by-default"} onClick={() => { props.sortByRelativity() }}>Osuvuuden mukaan</span>
          </div>
          <div styleName="search-sorting-mobile" onClick={() => toggle()}>
            <div styleName="sort-by-mobile">
              <span>Järjestä: </span>
              {props.dates && <span styleName="by-date">Päivämäärän mukaan</span>}
              {props.byRelativity && <span styleName="by-reletivity">Osuvuuden mukaan</span>}
              <span styleName="span-arrow">
                <i className="ri-arrow-drop-down-line"></i>
              </span>
            </div>
            {isTrue &&
            <ul styleName="drop-down" onClick={() => toggle()}>
              <li styleName="sort-by" onClick={() => { props.sortByDate() }}>Päivämäärän mukaan</li>
              <li styleName="sort-by" onClick={() => { props.sortByRelativity() }}>Osuvuuden mukaan</li>
            </ul>
            }

          </div>

          {
            !props.byRelativity&&!props.isOrdered&&
            <Pagination
            stories={props.orderedStories}
            PaginationComponent={PaginationStory}
            pageLimit={4}
            storyLimit={props.storyLimit}
            />
          }
          {
            props.isOrdered&&
            <Pagination
              stories={props.orderedStories}
              PaginationComponent={PaginationStory}
              pageLimit={4}
              storyLimit={props.storyLimit}
            />
          }
          {
            props.byRelativity&&
              <Pagination
                stories={props.storiesData}
                PaginationComponent={PaginationStory}
                pageLimit={4}
                storyLimit={props.storyLimit}
              />
          }
        </div>
      ) : (
        <h3 styleName="noresult-headline">Haullasi ei löytynyt tuloksia, muuta hakusanaa ja kokeile uudelleen.</h3>
      )}
    </div>
  );
}
LoadMoreSearchResults.propTypes = {
  data: object,
  itemsToLoad: number,
  query: string,
  isOrdered: bool,
  sortByDate: func,
  sortByRelativity: func,
  storiesData: array,
  byRelativity: bool,
  storyLimit: number,
  dates: bool,
};

export default LoadMoreSearchResults;
