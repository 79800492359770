/* eslint-disable import/extensions */
import { bool, number, object, shape } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { LazyCollection, LazyLoadImages } from "@quintype/components";
import { getCollectionTemplate } from "../../get-collection-template";
import { Suurtaulu } from "../../atoms/suurtaulu";
import { IppeTop } from "../../atoms/ippe-top";
import SideBar from "../../molecules/sidebar";
import "./urheilu-ippe.m.css";

const UrheiluIppe = ({ data, reloadTimeInMinutes = 15, shouldReload = false, collection }) => {
  const { mainTopics } = data;
  var sportsCollection;

  if (data.collection.name.includes("Kiekko")) {
    sportsCollection = "kiekko";
  } else if (data.collection.name.includes("Pesis")) {
    sportsCollection = "pesis";
  } else if (data.collection.name.includes("Salibandy")) {
    sportsCollection = "salibandy";
  } else {
    sportsCollection = "koontisivu";
  }

  useEffect(() => {
    if (!shouldReload) return;
    let timeElapsed = 0;
    let timerId;

    const Main = () => {
      if (document.hidden && timeElapsed === 0) {
        startTimer();
      }
      if (!document.hidden && timeElapsed > 0 && timeElapsed < reloadTimeInMinutes) {
        clearInterval(timerId);
        timeElapsed = 0;
      }
      if (!document.hidden && timeElapsed >= reloadTimeInMinutes) {
        window.location.reload();
      }
    };

    function updateTimeElapsed() {
      timeElapsed = timeElapsed + 1;
    }
    function startTimer() {
      timerId = window.setInterval(updateTimeElapsed, 60000);
    }
    document.addEventListener("visibilitychange", Main);
    return () => {
      document.removeEventListener("visibilitychange", Main);
    };
  }, []);


  return (
    <React.Fragment>
    <div styleName="top-container">
      <div styleName="container">
        <div styleName="main">
          <div styleName="ad-container">
            {/* PARAATI mobiili*/}
            <div styleName="mob-ad">
              <div id="sas_63333" data-placement-id="paraatipaikka-mobile"></div>
            </div>
            {/* PARAATI deskari ja tabletti*/}
            <div styleName="dsktop-ad">
              <div id="sas_63219" data-placement-id="paraatipaikka-desktop"></div>
            </div>
          </div>
          <div styleName="ad-container">
            {/* PARAATI 2 mobiili*/}
            <div styleName="mob-ad">
              <div id="sas_112313" data-placement-id="paraatipaikka-2-mobile"></div>
            </div>
            {/* PARAATI 2 deskari ja tabletti*/}
            <div styleName="dsktop-ad">
              <div id="sas_112312" data-placement-id="paraatipaikka-2-desktop"></div>
            </div>
          </div>
        </div>

        <IppeTop slug={data.collection.slug}/>

        <div styleName="lazy-pop">
          <div styleName="lazy-load">
            {(data.collection !== undefined &&
              <div styleName={`section-top ${sportsCollection}`}>
                <div styleName="section-title">
                  {data.collection.name.replace(/ *\([^)]*\) */g, "")}
                </div>
              </div>
            )}
            <LazyLoadImages>
              <LazyCollection collection={data.collection} collectionTemplates={getCollectionTemplate} lazyAfter={22} />
            </LazyLoadImages>
          </div>
          <SideBar useCollection={"urheilu-ilkka-pohjalainen"} />
          </div>
        </div>
        <Suurtaulu />
      </div>
    </React.Fragment>
  );
};

UrheiluIppe.propTypes = {
  data: shape({
    collection: object
  }),
  reloadTimeInMinutes: number,
  shouldReload: bool
};

function mapStateToProps(state) {
  return {
    shouldReload: get(state, ["qt", "config", "publisher-attributes", "shouldReload"], false),
    reloadTimeInMinutes: Number(get(state, ["qt", "config", "publisher-attributes", "reloadTimeInMinutes"], 15))
  };
}

export const UrheiluIppePage = connect(mapStateToProps, () => ({}))(UrheiluIppe);
