import React from "react";
import get from "lodash/get";
import { object } from "prop-types";
import { collectionToStories, Link } from "@quintype/components";
import CardImageType4 from "../../molecules/card-image-type-4";
import { SectionCategoryTitle } from "../../molecules/section-category-title";
//import  CollectionEnd  from "../../molecules/collection-end";
import "./two-cols-three-rows.m.css";

const TwoRowsThreeCols = ({ collection }) => {
  const theme = get(collection, ["associated-metadata", "dark_bg"], false);
  const adConfig = get(collection, ["associated-metadata", "show_ad"], true);
  const showSection = get(collection, ["associated-metadata", "Näytä_kokoelman_otsikko"], false);
  const storiesFromCollection = collectionToStories(collection);
  if (storiesFromCollection.length < 1) {
    return null;
  }

  return (
    <React.Fragment>
      <div styleName={`wrapper ${theme ? "dark-bg" : "light-bg"}`}>
        <div styleName="container">
          <div styleName="inner-wrapper1">
          {showSection &&<div styleName="collection-name"><SectionCategoryTitle name={collection.name} /></div>}
            <div styleName="item-wrapper1">
              {storiesFromCollection.length >= 1 &&
                storiesFromCollection.slice(0,2).map((item, index) =>
                  <React.Fragment key={index}>
                    <div styleName="item item-each" key={item.id}>
                      <CardImageType4 story={item} />
                    </div>
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

TwoRowsThreeCols.propTypes = {
  collection: object
};

export { TwoRowsThreeCols };

TwoRowsThreeCols.storyLimit = 12;
